import React, { FC, useEffect, useState } from "react";
import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters/TabFilters";
import Heading2 from "components/Heading/Heading2";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useFiltersContext } from "contexts/FilterContext";
import { useLocationContext } from "contexts/LocationContext"; // Importar el contexto de ubicación

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  onToggleMap?: () => void;
  isMapVisible?: boolean;
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS;
const ITEMS_PER_PAGE = 8;

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  onToggleMap,
  isMapVisible = false,
}) => {
  const { t, i18n } = useTranslation("heading2");
  const { from, to } = useDatesContext();
  const { adults, children, infants } = useGuestsContext();
  const { location } = useLocationContext(); // Usar el contexto de ubicación
  const {
    priceRange,
    selectedTypes,
    guestCount,
    bedsCount,
    selectedAmenities,
    selectedFacilities,
  } = useFiltersContext();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(window.innerWidth >= 1280);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Formato de fechas basado en el idioma actual
  const dateFormatOptions: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit" };
  const formattedFromDate = from ? from.toLocaleDateString(i18n.language, dateFormatOptions) : "";
  const formattedToDate = to ? to.toLocaleDateString(i18n.language, dateFormatOptions) : "";

  // Calcular el total de huéspedes desde el contexto
  const totalGuests = adults + children + infants;

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth >= 1280);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [priceRange, selectedTypes, guestCount, bedsCount, selectedAmenities, selectedFacilities]);

  const filteredData = data.filter((stay) => {
    const price = parseFloat(stay.price.replace(/[$,]/g, ""));
    const isWithinPriceRange = price >= priceRange[0] && price <= priceRange[1];
    const isSelectedType = selectedTypes.length === 0 || selectedTypes.includes(stay.type);
    const isWithinGuestCount = guestCount === 0 || stay.maxGuests >= guestCount;
    const isWithinBedsCount = bedsCount === 0 || stay.beds >= bedsCount;
    const hasSelectedAmenities =
      selectedAmenities.length === 0 ||
      selectedAmenities.every((amenity) => stay.amenities.includes(amenity));
    const hasSelectedFacilities =
      selectedFacilities.length === 0 ||
      selectedFacilities.every((facility) => stay.facilities.includes(facility));

    return (
      isWithinPriceRange &&
      isSelectedType &&
      isWithinGuestCount &&
      isWithinBedsCount &&
      hasSelectedAmenities &&
      hasSelectedFacilities
    );
  });

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      <Heading2
        onToggleMap={isLargeScreen ? onToggleMap : undefined}
        isMapVisible={isMapVisible}
        heading={`${t("staysIn")} ${location || "Destino"}`} // Usar `location` desde el contexto o "Destino"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {formattedFromDate} - {formattedToDate}
            <span className="mx-2">·</span>
            {t("numberOfGuests", { count: totalGuests })}
          </span>
        }
      />
      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {paginatedData.length === 0 ? (
          <p>{t("noCards")}</p>
        ) : (
          paginatedData.map((stay) => <StayCard key={stay.id} data={stay} />)
        )}
      </div>

      <div className="flex mt-16 justify-center items-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
