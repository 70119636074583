import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import convertNumbThousand from "utils/convertNumbThousand";
import { useTranslation } from "react-i18next";
import { useExperiencesFilterContext } from "contexts/ExperiencesFilterContext";

const typeOfExperience = [
  { name: "foodAndDrinks", descriptionKey: "foodAndDrinksDescription" },
  { name: "artAndCulture", descriptionKey: "artAndCultureDescription" },
  { name: "natureAndOutdoors", descriptionKey: "natureAndOutdoorsDescription" },
  { name: "sports", descriptionKey: "sportsDescription" },
];

const timeOfDayOptions = [
  { name: "morning", descriptionKey: "morningDescription" },
  { name: "afternoon", descriptionKey: "afternoonDescription" },
  { name: "evening", descriptionKey: "eveningDescription" },
];

const TabFilters: React.FC = () => {
  const { t } = useTranslation("tabFilters.Experiences");

  const {
    priceRange,
    setPriceRange,
    selectedTypes,
    setSelectedTypes,
    selectedTimeOfDay,
    setSelectedTimeOfDay,
    isSaleOffActive,
    toggleSaleOffFilter,
  } = useExperiencesFilterContext();

  const [rangePrices, setRangePricesState] = useState(priceRange);
  const [tempSelectedExperienceTypes, setTempSelectedExperienceTypes] = useState(selectedTypes);
  const [tempSelectedTimeOfDay, setTempSelectedTimeOfDay] = useState(selectedTimeOfDay);

  useEffect(() => {
    setTempSelectedExperienceTypes(selectedTypes);
    setTempSelectedTimeOfDay(selectedTimeOfDay);
    setRangePricesState(priceRange);
  }, [selectedTypes, selectedTimeOfDay, priceRange]);

  const handleTypeChange = (type: string) => {
    setTempSelectedExperienceTypes((prevTypes) =>
      prevTypes.includes(type) ? prevTypes.filter((t) => t !== type) : [...prevTypes, type]
    );
  };

  const handleTimeChange = (time: string) => {
    setTempSelectedTimeOfDay((prevTimes) =>
      prevTimes.includes(time) ? prevTimes.filter((t) => t !== time) : [...prevTimes, time]
    );
  };

  const handleApply = () => {
    setSelectedTypes(tempSelectedExperienceTypes);
    setSelectedTimeOfDay(tempSelectedTimeOfDay);
    setPriceRange(rangePrices);
  };

  const renderXClear = () => (
    <span
      className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer"
      onClick={() => toggleSaleOffFilter()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );

  const renderTabsTypeOfExperience = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
          >
            <span>{t("typeOfExperience")}</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {typeOfExperience.map((item) => (
                    <div key={item.name}>
                      <Checkbox
                        name={item.name}
                        label={t(item.name)}
                        subLabel={t(item.descriptionKey)}
                        checked={tempSelectedExperienceTypes.includes(item.name)}
                        onChange={() => handleTypeChange(item.name)}
                      />
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setTempSelectedExperienceTypes([])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      handleApply();
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  const renderTabsPriceRange = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-400 bg-primary-50 text-primary-400 focus:outline-none">
            <span>{`$${convertNumbThousand(rangePrices[0])} - $${convertNumbThousand(rangePrices[1])}`}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-8">
                  <span className="font-medium">{t("pricePerDay")}</span>
                  <Slider
                    range
                    min={0}
                    max={2000}
                    value={rangePrices}
                    onChange={(value) => setRangePricesState(value as number[])}
                  />
                  <div className="flex justify-between space-x-5">
                    <div className="w-1/2">
                      <label htmlFor="minPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("minPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="minPrice"
                          id="minPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={rangePrices[0]}
                          min={0}
                          max={rangePrices[1]}
                          onChange={(e) => setRangePricesState([Number(e.target.value), rangePrices[1]])}
                        />
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="maxPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("maxPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="maxPrice"
                          id="maxPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={rangePrices[1]}
                          min={rangePrices[0]}
                          max={2000}
                          onChange={(e) => setRangePricesState([rangePrices[0], Number(e.target.value)])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setRangePricesState([0, 2000])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      setPriceRange(rangePrices);
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  const renderTabsTimeOfDay = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500" : ""}`}
          >
            <span>{t("timeOfDay")}</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {timeOfDayOptions.map((item) => (
                    <div key={item.name}>
                      <Checkbox
                        name={item.name}
                        label={t(item.name)}
                        subLabel={t(item.descriptionKey)}
                        checked={tempSelectedTimeOfDay.includes(item.name)}
                        onChange={() => handleTimeChange(item.name)}
                      />
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setTempSelectedTimeOfDay([])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      handleApply();
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfExperience()}
        {renderTabsPriceRange()}
        {renderTabsTimeOfDay()}
        <button
          onClick={toggleSaleOffFilter}
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none z-20 ${
            isSaleOffActive
              ? "border-primary-500 bg-primary-100 text-primary-500"
              : "border-neutral-300 hover:border-neutral-400"
          }`}
        >
          <span>{t("saleOffFilter")}</span>
          {isSaleOffActive && renderXClear()}
        </button>
      </div>
    </div>
  );
};

export default TabFilters;
