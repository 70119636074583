import React, { useState, FC, useEffect } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useLocationContext } from "contexts/LocationContext";
import { useTranslation } from "react-i18next";

const StaySearchForm: FC = () => {
  const { t } = useTranslation("errors");
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);

  const { from, to, setFrom, setTo } = useDatesContext();
  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();
  const { location, setLocation } = useLocationContext();

  const [locationError, setLocationError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const saveSearchFilterToLocalStorage = () => {
    const searchFilter = {
      location,
      locationCountry: "España",
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      adult: adults,
      children,
      infant: infants,
    };
    localStorage.setItem("searchFilter", JSON.stringify(searchFilter));
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
      setFrom(savedFilter.from ? new Date(savedFilter.from) : null);
      setTo(savedFilter.to ? new Date(savedFilter.to) : null);
      setAdults(savedFilter.adult || 2);
      setChildren(savedFilter.children || 0);
      setInfants(savedFilter.infant || 0);
      setLocation(savedFilter.location || "");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setFrom, setTo, setAdults, setChildren, setInfants, setLocation]);

  const handleLocationSelected = (selectedLocation: string) => {
    setLocation(selectedLocation);
    setLocationError(false);
    saveSearchFilterToLocalStorage();
  };

  const handleDatesChange = (startDate: Date | null, endDate: Date | null) => {
    setFrom(startDate);
    setTo(endDate);
    setDateError(false);
    saveSearchFilterToLocalStorage();
  };

  const handleOpenModal = (modalName: string) => {
    setIsLocationOpen(modalName === "location");
    setIsDatePickerOpen(modalName === "dates");
    setIsGuestsOpen(modalName === "guests");
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const validateFields = () => {
    let isValid = true;
    if (!location) {
      setLocationError(true);
      isValid = false;
    }
    if (!from || !to) {
      setDateError(true);
      isValid = false;
    }

    if (!isValid) {
      setIsLocationOpen(false);
      setIsDatePickerOpen(false);
      setIsGuestsOpen(false);
    }

    return isValid;
  };

  // Manejar tecla Escape y clic fuera del modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsLocationOpen(false);
        setIsDatePickerOpen(false);
        setIsGuestsOpen(false);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Verificar si el clic se hizo fuera de los inputs abiertos
      if (
        !target.closest(".location-input") &&
        !target.closest(".stay-dates-range-input") &&
        !target.closest(".guests-input")
      ) {
        setIsLocationOpen(false);
        setIsDatePickerOpen(false);
        setIsGuestsOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full">
      <form className="relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        <LocationInput
          className="flex-[1.5] location-input"
          onLocationSelected={handleLocationSelected}
          isOpen={isLocationOpen}
          onOpen={() => handleOpenModal("location")}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

        <StayDatesRangeInput
          className="flex-1 stay-dates-range-input"
          isOpen={isDatePickerOpen}
          onOpen={() => handleOpenModal("dates")}
          onClose={handleCloseDatePicker}
          onDateChange={handleDatesChange}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

        <GuestsInput
          className="flex-1 guests-input"
          onOpen={() => handleOpenModal("guests")}
          buttonSubmitHref="/search"
          validateFields={validateFields}
        />
      </form>

      <div className="mt-2 text-center">
        {locationError && <p className="text-red-500 text-sm">{t("noLocation")}</p>}
        {dateError && <p className="text-red-500 text-sm">{t("noDates")}</p>}
      </div>
    </div>
  );
};

export default StaySearchForm;
