import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { es, enUS } from 'date-fns/locale';

const DatePickerCustomHeaderTwoMonth2 = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => {
  const { t, i18n } = useTranslation('stayDatesRangeInput'); 

  return (
    <div className="flex justify-between items-center">
      <button
        aria-label={t("previousMonth")} 
        className={`react-datepicker__navigation react-datepicker__navigation--previous ${customHeaderCount === 1 ? "invisible" : ""}`}
        onClick={decreaseMonth}
        type="button"
      >
        <ChevronLeftIcon className="w-5 h-5" />
      </button>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString(i18n.language, {
          month: "long",
          year: "numeric",
        })}
      </span>
      <button
        aria-label={t("nextMonth")} 
        className={`react-datepicker__navigation react-datepicker__navigation--next ${customHeaderCount === 0 ? "invisible" : ""}`}
        type="button"
        onClick={increaseMonth}
      >
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

export default DatePickerCustomHeaderTwoMonth2;
