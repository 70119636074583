import { FC } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { LatLngExpression, LatLngBounds } from "leaflet";
import { CarDataType, ExperiencesDataType, StayDataType } from "data/types";
import CustomMarkerLayer from "./CustomMarkerLayer";  // Importamos el CustomMarkerLayer

interface MapContainerProps {
  currentHoverID: string | number;
  DEMO_DATA: (CarDataType | ExperiencesDataType | StayDataType)[];
  listingType: "car" | "experiences" | "stay";
}

const MapContainerComponent: FC<MapContainerProps> = ({
  currentHoverID = -1,
  DEMO_DATA,
  listingType,
}) => {
  // Comprobar si hay datos disponibles
  if (DEMO_DATA.length === 0) {
    return <div>No hay datos disponibles para mostrar en el mapa.</div>; // Mensaje cuando no hay datos
  }

  // Asignar valores de latitud y longitud de la primera entrada de DEMO_DATA
  const defaultCenter: LatLngExpression = [
    DEMO_DATA[0].map.lat,
    DEMO_DATA[0].map.lng,
  ];

  const bounds: LatLngBounds = new LatLngBounds(
    [defaultCenter[0] - 90, defaultCenter[1] - 180],
    [defaultCenter[0] + 90, defaultCenter[1] + 180]
  );

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <MapContainer
        center={defaultCenter}
        zoom={12}
        minZoom={3}
        maxZoom={18}
        maxBounds={bounds}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* Capa personalizada para renderizar las cajitas */}
        <CustomMarkerLayer
          currentHoverID={currentHoverID}
          DEMO_DATA={DEMO_DATA}
          listingType={listingType}
        />
      </MapContainer>
    </div>
  );
};

export default MapContainerComponent;
