import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListing8Props {}

const PageAddListing8: FC<PageAddListing8Props> = () => {
  // Estado para manejar los datos del formulario
  const [formData, setFormData] = useState({
    currency: "USD", // Valor predeterminado del selector de moneda
    basePriceWeekdays: "", // Precio base para lunes - jueves
    basePriceWeekends: "", // Precio base para viernes - domingo
    monthlyDiscount: "", // Descuento mensual
  });

  // Cargar los datos desde localStorage cuando el componente se monta
  useEffect(() => {
    const savedData = localStorage.getItem("pageAddListing8");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // Guardar los datos en localStorage cuando se pulsa "Continue"
  const handleSaveData = () => {
    localStorage.setItem("pageAddListing8", JSON.stringify(formData));
  };

  // Manejar cambios en los inputs y el selector
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    // Asegurar que solo se permitan números en los campos de precios
    if (name !== "currency" && isNaN(Number(value))) {
      return; // Evita que se guarde cualquier valor no numérico en los campos de precio
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Actualizamos el campo correspondiente
    }));
  };

  return (
    <CommonLayout
      index="08"
      backtHref="/add-listing-7"
      nextHref="/add-listing-9"
      onNext={handleSaveData} // Guardar los datos cuando se pulse "Continue"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Price your space</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The host's revenue is directly dependent on the setting of rates and regulations on the number of guests, the number of nights, and the cancellation policy.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <div className="space-y-8">
          {/* Currency */}
          <FormItem label="Currency">
            <Select
              name="currency"
              value={formData.currency} // Valor seleccionado del estado
              onChange={handleInputChange}
            >
              <option value="USD">USD</option>
              <option value="VND">VND</option>
              <option value="EUR">EUR</option>
            </Select>
          </FormItem>

          {/* Base price weekdays */}
          <FormItem label="Base price  (Monday - Thursday)">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">$</span>
              </div>
              <Input
                name="basePriceWeekdays"
                value={formData.basePriceWeekdays} // Valor del campo desde el estado
                onChange={handleInputChange}
                className="!pl-8 !pr-10"
                placeholder="0.00"
                type="text" // Para que sea compatible con solo números
                inputMode="numeric" // Asegurar que el teclado numérico se muestre en dispositivos móviles
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">{formData.currency}</span>
              </div>
            </div>
          </FormItem>

          {/* Base price weekends */}
          <FormItem label="Base price  (Friday - Sunday)">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">$</span>
              </div>
              <Input
                name="basePriceWeekends"
                value={formData.basePriceWeekends} // Valor del campo desde el estado
                onChange={handleInputChange}
                className="!pl-8 !pr-10"
                placeholder="0.00"
                type="text" // Para que sea compatible con solo números
                inputMode="numeric" // Asegurar que el teclado numérico se muestre en dispositivos móviles
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">{formData.currency}</span>
              </div>
            </div>
          </FormItem>

          {/* Long term price */}
          <FormItem label="Long term price (Monthly discount)">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">%</span>
              </div>
              <Input
                name="monthlyDiscount"
                value={formData.monthlyDiscount} // Valor del campo desde el estado
                onChange={handleInputChange}
                className="!pl-8 !pr-10"
                placeholder="0.00"
                type="text" // Para que sea compatible con solo números
                inputMode="numeric" // Asegurar que el teclado numérico se muestre en dispositivos móviles
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">every month</span>
              </div>
            </div>
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing8;
