import React, { createContext, useContext, useState } from "react";

interface FiltersContextProps {
  priceRange: number[];
  setPriceRange: (value: number[]) => void;
  selectedTypes: string[];
  setSelectedTypes: (types: string[]) => void;
  guestCount: number;
  setGuestCount: (count: number) => void;
  bedsCount: number;
  setBedsCount: (count: number) => void;
  selectedAmenities: string[];
  setSelectedAmenities: (amenities: string[]) => void;
  selectedFacilities: string[];
  setSelectedFacilities: (facilities: string[]) => void;
}

const FiltersContext = createContext<FiltersContextProps | undefined>(undefined);

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [priceRange, setPriceRange] = useState<number[]>([0, 1000]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [guestCount, setGuestCount] = useState(0);
  const [bedsCount, setBedsCount] = useState(0);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>([]);

  return (
    <FiltersContext.Provider
      value={{
        priceRange,
        setPriceRange,
        selectedTypes,
        setSelectedTypes,
        guestCount,
        setGuestCount,
        bedsCount,
        setBedsCount,
        selectedAmenities,
        setSelectedAmenities,
        selectedFacilities,
        setSelectedFacilities,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error("useFiltersContext must be used within a FiltersProvider");
  }
  return context;
};
