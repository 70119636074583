import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface ExperienceGuestsContextType {
  adults: number;
  children: number;
  infants: number;
  setAdults: (value: number) => void;
  setChildren: (value: number) => void;
  setInfants: (value: number) => void;
}

const ExperienceGuestsContext = createContext<ExperienceGuestsContextType | undefined>(undefined);

export const ExperienceGuestsProvider: React.FC<{ children: ReactNode }> = ({ children: contextChildren }) => {
  const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");

  const [adults, setAdultsState] = useState<number>(() => savedFilter.adult ?? 1);
  const [children, setChildrenState] = useState<number>(() => savedFilter.children ?? 0);
  const [infants, setInfantsState] = useState<number>(() => savedFilter.infant ?? 0);

  useEffect(() => {
    const savedFilter = {
      adult: adults,
      children: children,
      infant: infants,
    };
    localStorage.setItem("searchFilterExperiences", JSON.stringify(savedFilter));
  }, [adults, children, infants]);

  const setAdults = (value: number) => setAdultsState(Math.max(value, 1));
  const setChildren = (value: number) => setChildrenState(value);
  const setInfants = (value: number) => setInfantsState(value);

  return (
    <ExperienceGuestsContext.Provider value={{ adults, children, infants, setAdults, setChildren, setInfants }}>
      {contextChildren}
    </ExperienceGuestsContext.Provider>
  );
};

export const useExperienceGuestsContext = () => {
  const context = useContext(ExperienceGuestsContext);
  if (!context) {
    throw new Error("useExperienceGuestsContext must be used within an ExperienceGuestsProvider");
  }
  return context;
};
