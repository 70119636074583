import React from "react";
import { ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FaMap } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  onToggleMap?: () => void;
  isMapVisible?: boolean;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading,
  subHeading,
  onToggleMap,
  isMapVisible = false,
}) => {
  const { t } = useTranslation("heading2");

  // Obtener fechas desde el contexto
  const { from, to } = useDatesContext();
  const { adults, children, infants } = useGuestsContext();

  const location = heading || t("checkIn");
  const checkIn = from
    ? new Date(from).toLocaleDateString("es-ES", { day: "2-digit", month: "short" })
    : t("checkIn");
  const checkOut = to
    ? new Date(to).toLocaleDateString("es-ES", { day: "2-digit", month: "short" })
    : t("checkOut");
  const totalGuests = adults + children + infants;

  return (
    <div className={`mb-12 lg:mb-16 ${className} mt-8 lg:mt-12`}>
      <div className="flex items-center">
        <h2 className="text-4xl font-semibold">
          {heading || `${t("staysIn")} ${location}`}
        </h2>
        {onToggleMap && (
          <div className="ml-4">
            <ButtonPrimary
              onClick={onToggleMap}
              className="flex items-center px-3 py-1 rounded-md text-sm bg-primary-400 hover:bg-primary-500"
            >
              <FaMap size={16} />
              <span className="ml-2">
                {isMapVisible ? t("hideMap") : t("showMap")}
              </span>
            </ButtonPrimary>
          </div>
        )}
      </div>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {`${checkIn} - ${checkOut}`}
          <span className="mx-2">·</span>
          {`${totalGuests} ${t("guests")}`}
        </span>
      )}
    </div>
  );
};

export default Heading2;
