import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface LocationContextType {
  location: string;
  locationCountry: string;
  setLocation: (location: string) => void;
  setLocationCountry: (country: string) => void;
}

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Cargar valores iniciales desde localStorage
  const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");

  const [location, setLocationState] = useState<string>(savedFilter.location || "");
  const [locationCountry, setLocationCountryState] = useState<string>(savedFilter.locationCountry || "");

  // Setters actualizados sin `localStorage`
  const setLocation = (newLocation: string) => setLocationState(newLocation);
  const setLocationCountry = (newLocationCountry: string) => setLocationCountryState(newLocationCountry);

  // Guardar en `localStorage` cada vez que cambian `location` o `locationCountry`
  useEffect(() => {
    const savedFilter = { location, locationCountry };
    localStorage.setItem("searchFilter", JSON.stringify(savedFilter));
  }, [location, locationCountry]);

  // Listener para sincronizar cambios en `localStorage`
  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
      setLocationState(savedFilter.location || "");
      setLocationCountryState(savedFilter.locationCountry || "");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <LocationContext.Provider value={{ location, locationCountry, setLocation, setLocationCountry }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocationContext must be used within a LocationProvider");
  }
  return context;
};
