import { useState, useEffect } from "react";

const useAuthCheck = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<{ avatar?: string } | null>(null);

  const checkAuth = () => {
    const token = localStorage.getItem("token");
    const avatar = localStorage.getItem("avatar"); // Recupéralo del localStorage
    if (token) {
      setIsAuthenticated(true);
      setUser({
        avatar: avatar || "src/images/avatars/default-avatar.png", // Ruta por defecto si no hay avatar
      });
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  useEffect(() => {
    // Verificar autenticación al montar el componente
    checkAuth();

    // Escuchar cambios en localStorage
    window.addEventListener("storage", checkAuth);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  // Devolvemos también la función setIsAuthenticated para poder actualizarla desde fuera
  return { isAuthenticated, user, setIsAuthenticated };
};

export default useAuthCheck;
