import React from "react";
import { Link } from "react-router-dom";
import bedliaLogo from "images/logos/bedlia-logo.svg"; 

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = "" }) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
      style={{ width: "8rem" }} // Establece un ancho de 8rem
    >
      <img
        src={bedliaLogo}  
        alt="Logo"
        className="block max-h-12 w-full" // Ajusta la imagen al 100% del ancho del contenedor
      />
    </Link>
  );
};

export default Logo;