// store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import searchReducer from '../components/HeroSearchForm/searchSlice';

const store = configureStore({
  reducer: {
    search: searchReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>; // Para usar en useSelector
export type AppDispatch = typeof store.dispatch; // Para usar en useDispatch
export default store;
