import React, { FC, useEffect, useState, startTransition } from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchFormSmall";
import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { useTranslation } from "react-i18next";

export type SearchTab = "Stays" | "Experiences";

export interface HeroSearchFormSmallProps {
  className?: string;
  defaultTab?: SearchTab;
  onTabChange?: (tab: SearchTab) => void;
  defaultFieldFocus?: StaySearchFormFields;
  searchValue?: string;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TABS: SearchTab[] = ["Stays", "Experiences"];

const HeroSearchFormSmall: FC<HeroSearchFormSmallProps> = ({
  className = "",
  defaultTab = "Stays",
  onTabChange,
  defaultFieldFocus,
  searchValue,
  onSearchChange,
}) => {
  const { t } = useTranslation("heroSearchForm");
  const [tabActive, setTabActive] = useState<SearchTab>(() => {
    const savedTab = localStorage.getItem("tabActiveSmall");
    return savedTab ? (savedTab as SearchTab) : defaultTab;
  });

  const [locationError, setLocationError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [activeModal, setActiveModal] = useState<StaySearchFormFields | undefined>(defaultFieldFocus);

  useEffect(() => {
    setTabActive(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (defaultFieldFocus) {
      setActiveModal(defaultFieldFocus);
    }
  }, [defaultFieldFocus]);

  const handleTabChange = (tab: SearchTab) => {
    startTransition(() => {
      setTabActive(tab);
    });
    localStorage.setItem("tabActiveSmall", tab);
    onTabChange && onTabChange(tab);
  };

  const handleCheckoutSelected = () => {
    console.log("Checkout selected");
  };

  const handleValidation = (errors: { locationError: boolean; dateError: boolean }) => {
    setLocationError(errors.locationError);
    setDateError(errors.dateError);
  };

  const renderTab = () => (
    <ul className="h-[88px] flex justify-center space-x-5 sm:space-x-9">
      {TABS.map((tab) => {
        const active = tab === tabActive;
        return (
          <li
            onClick={() => handleTabChange(tab)}
            className={`relative flex-shrink-0 flex items-center cursor-pointer text-base ${
              active
                ? "text-neutral-900 dark:text-neutral-200 font-medium"
                : "text-neutral-500 dark:text-neutral-300"
            }`}
            key={tab}
          >
            <div className="relative select-none">
              <span>{t(tab.toLowerCase())}</span>
              {active && (
                <span className="absolute top-full mt-1 block w-full h-0.5 rounded-full bg-neutral-800 dark:bg-neutral-100" />
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );

  const renderForm = () => {
    switch (tabActive) {
      case "Stays":
        return (
          <StaySearchForm
            defaultFieldFocus={activeModal}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            onCheckoutSelected={handleCheckoutSelected}
            onValidate={handleValidation}
          />
        );
      case "Experiences":
        return (
          <ExperiencesSearchForm
            defaultFieldFocus={activeModal}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            onCheckoutSelected={handleCheckoutSelected}
            onValidate={handleValidation}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={`nc-HeroSearchFormSmall ${className}`} data-nc-id="HeroSearchFormSmall">
      {renderTab()}
      <div className="mt-2">{renderForm()}</div>

      <div className="mt-4 text-center">
  {locationError && (
    <p className="text-red-500 text-sm">{t("errors:noLocation")}</p>
  )}
  {!locationError && dateError && (
    <p className="text-red-500 text-sm">{t("errors:noDates")}</p>
  )}
</div>


    </div>
  );
};

export default HeroSearchFormSmall;
