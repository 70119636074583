import React, { FC, Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth2 from "components/DatePickerCustomHeaderTwoMonth2";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  onCheckoutSelected: () => void;
  onDatesChange?: (startDate: Date | null, endDate: Date | null) => void;
  isOpen?: boolean;
  onClick?: () => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding--small ]",
  onCheckoutSelected,
  onDatesChange,
  isOpen: externalIsOpen = false,
  onClick,
}) => {
  const { t } = useTranslation("stayDatesRangeInput");
  const { from: startDate, to: endDate, setFrom: setStartDate, setTo: setEndDate } = useDatesContext();

  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(externalIsOpen);

  // Definir la fecha actual
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useOutsideAlerter(refContainer, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(externalIsOpen);
  }, [externalIsOpen]);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    // Validar que las fechas no sean anteriores a hoy
    if (start && start < today) {
      setStartDate(null);
      return;
    }
    if (end && end < today) {
      setEndDate(null);
      return;
    }

    // Actualizar las fechas
    setStartDate(start);
    setEndDate(end);

    if (onDatesChange) {
      onDatesChange(start, end);
    }

    // Guardar en localStorage y cerrar el modal si ambas fechas están seleccionadas
    if (start && end) {
      const searchFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
      localStorage.setItem(
        "searchFilter",
        JSON.stringify({
          ...searchFilter,
          from: start.toISOString(),
          to: end.toISOString(),
        })
      );
      onCheckoutSelected();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
      setStartDate(savedFilter.from ? new Date(savedFilter.from) : null);
      setEndDate(savedFilter.to ? new Date(savedFilter.to) : null);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [setStartDate, setEndDate]);

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
    const updatedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
    updatedFilter.from = null;
    updatedFilter.to = null;
    localStorage.setItem("searchFilter", JSON.stringify(updatedFilter));
  };

  const renderInput = () => (
    <div className="flex-grow text-left">
      <span className="block xl:text-base font-semibold">
        {startDate?.toLocaleDateString("es-ES", {
          month: "short",
          day: "2-digit",
        }) || t("addDates")}
        {endDate
          ? " - " +
            endDate?.toLocaleDateString("es-ES", {
              month: "short",
              day: "2-digit",
            })
          : ""}
      </span>
      <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
        {t("checkInCheckOut")}
      </span>
    </div>
  );

  return (
    <div ref={refContainer} className={`StayDatesRangeInput relative flex ${className}`}>
      <div
        className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 cursor-pointer focus:outline-none ${
          isOpen ? "nc-hero-field-focused--2" : ""
        }`}
        onClick={() => {
          setIsOpen(!isOpen);
          if (onClick) onClick();
        }}
      >
        {renderInput()}
        {startDate && isOpen && <ClearDataButton onClick={handleClearDates} />}
      </div>

      {isOpen && (
        <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
      )}

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
          <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
            <DatePicker
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              minDate={today}
              monthsShown={2}
              showPopperArrow={false}
              inline
              renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth2 {...p} />}
              renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
            />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default StayDatesRangeInput;
