import React, { FC, useEffect, useState } from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters/TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import { useTranslation } from "react-i18next";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";
import { useExperiencesFilterContext } from "contexts/ExperiencesFilterContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: ExperiencesDataType[];
  onToggleMap?: () => void;
  isMapVisible?: boolean;
}

const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS;
const ITEMS_PER_PAGE = 8;

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  onToggleMap,
  isMapVisible = false,
}) => {
  const { t, i18n } = useTranslation("experiences");
  const { from, to } = useExperienceDatesContext();
  const { adults, children, infants } = useExperienceGuestsContext();
  const {
    priceRange,
    selectedTypes,
    selectedTimeOfDay,
    isSaleOffActive,
  } = useExperiencesFilterContext();
  const { location } = useExperienceLocationContext();

  const [filteredData, setFilteredData] = useState<ExperiencesDataType[]>(data);
  const [currentPage, setCurrentPage] = useState(1);

  // Filtrar las experiencias cada vez que cambien los filtros del contexto
  useEffect(() => {
    const updatedData = data.filter((experience) => {
      const numericPrice = parseFloat(experience.price.replace("$", ""));
      const matchesPriceRange = numericPrice >= priceRange[0] && numericPrice <= priceRange[1];
      const matchesSaleOff = !isSaleOffActive || experience.saleOff !== null;
      const matchesType =
        selectedTypes.length === 0 ||
        (experience.typeOfExperience && selectedTypes.includes(experience.typeOfExperience));
      const matchesTimeOfDay =
        selectedTimeOfDay.length === 0 ||
        experience.timeOfDayOptions?.some((time) => selectedTimeOfDay.includes(time));

      return matchesPriceRange && matchesSaleOff && matchesType && matchesTimeOfDay;
    });

    setFilteredData(updatedData);
    setCurrentPage(1);
  }, [priceRange, data, isSaleOffActive, selectedTypes, selectedTimeOfDay]);

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Formato de fecha basado en el idioma actual
  const dateFormat: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit" };
  const formattedFrom = from
    ? new Date(from).toLocaleDateString(i18n.language, dateFormat)
    : "";
  const formattedTo = to
    ? new Date(to).toLocaleDateString(i18n.language, dateFormat)
    : "";

  // Calcular el total de huéspedes
  const guestCount = adults + children + infants;

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
      <Heading2
        onToggleMap={onToggleMap}
        isMapVisible={isMapVisible}
        heading={`${t("experiencesIn")} ${location || "Destino"}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {t("numberOfExperiences", { count: filteredData.length })}
            <span className="mx-2">·</span>
            {from && to ? `${formattedFrom} - ${formattedTo}` : t("dates")}
            <span className="mx-2">·</span>
            {t("numberOfGuests", { count: guestCount })}
          </span>
        }
      />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {paginatedData.map((experience: ExperiencesDataType) => (
          <ExperiencesCard key={experience.id} data={experience} />
        ))}
      </div>

      <div className="flex mt-16 justify-center items-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
