import React, { FC } from "react";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`inline-flex w-10 h-10 items-center justify-center rounded-full ${
            currentPage === i
              ? "bg-primary-400 text-white" 
              : "bg-white text-neutral-6000 border border-neutral-200 hover:bg-neutral-100 dark:text-neutral-400 dark:bg-neutral-900 dark:border-neutral-700 dark:hover:bg-neutral-800"
          }`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <nav
      className="nc-Pagination inline-flex space-x-2 text-base font-medium"
      style={{ zIndex: 1, position: "relative" }} 
    >
      {renderPageNumbers()}
    </nav>
  );
};

export default Pagination;
