import React, { createContext, useContext, useEffect, useState } from "react";

interface ExperienceDatesContextType {
  from: Date | null;
  to: Date | null;
  setFrom: (date: Date | null) => void;
  setTo: (date: Date | null) => void;
}

const ExperienceDatesContext = createContext<ExperienceDatesContextType | undefined>(undefined);

export const ExperienceDatesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [from, setFrom] = useState<Date | null>(() => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");
    return savedFilter.from ? new Date(savedFilter.from) : null;
  });

  const [to, setTo] = useState<Date | null>(() => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");
    return savedFilter.to ? new Date(savedFilter.to) : null;
  });

  useEffect(() => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");
    savedFilter.from = from ? from.toISOString() : null;
    savedFilter.to = to ? to.toISOString() : null;
    localStorage.setItem("searchFilterExperiences", JSON.stringify(savedFilter));
  }, [from, to]);

  return (
    <ExperienceDatesContext.Provider value={{ from, to, setFrom, setTo }}>
      {children}
    </ExperienceDatesContext.Provider>
  );
};

export const useExperienceDatesContext = () => {
  const context = useContext(ExperienceDatesContext);
  if (!context) {
    throw new Error("useExperienceDatesContext must be used within an ExperienceDatesProvider");
  }
  return context;
};
