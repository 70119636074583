// searchSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchState {
  location: string;
  dates: {
    start: string | null;
    end: string | null;
  };
  guests: {
    adults: number;
    children: number;
    infants: number;
  };
}

const initialState: SearchState = {
  location: '',
  dates: {
    start: null,
    end: null,
  },
  guests: {
    adults: 0,
    children: 0,
    infants: 0,
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setDates: (state, action: PayloadAction<{ start: string | null; end: string | null }>) => {
      state.dates = action.payload;
    },
    setGuests: (state, action: PayloadAction<{ adults: number; children: number; infants: number }>) => {
      state.guests = action.payload;
    },
  },
});

export const { setLocation, setDates, setGuests } = searchSlice.actions;
export default searchSlice.reducer;
