import React, { FC, useEffect, useCallback, useState } from "react";
import LocationInput from "../LocationInputSmall";
import GuestsInput from "../GuestsInputSmall";
import StayDatesRangeInput from "./StayDatesRangeInputSmall";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";

export interface StaySearchFormProps {
  defaultFieldFocus?: StaySearchFormFields;
  searchValue?: string;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckoutSelected: () => void;
  onValidate?: (errors: { locationError: boolean; dateError: boolean }) => void;
}

const StaySearchForm: FC<StaySearchFormProps> = ({
  defaultFieldFocus,
  searchValue,
  onSearchChange,
  onCheckoutSelected,
  onValidate,
}) => {
  const { from, to, setFrom, setTo } = useDatesContext();
  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();

  const [location, setLocation] = useState(() => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
    return savedFilter.location || "";
  });

  const [activeInput, setActiveInput] = useState<StaySearchFormFields | null>(defaultFieldFocus || null);

  useEffect(() => {
    if (defaultFieldFocus) {
      setActiveInput(defaultFieldFocus);
    }
  }, [defaultFieldFocus]);

  const saveToLocalStorage = () => {
    const searchFilter = {
      location,
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      adult: adults,
      children: children,
      infant: infants,
    };
    localStorage.setItem("searchFilter", JSON.stringify(searchFilter));
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
      setFrom(savedFilter.from ? new Date(savedFilter.from) : null);
      setTo(savedFilter.to ? new Date(savedFilter.to) : null);
      setLocation(savedFilter.location || "");
      setAdults(savedFilter.adult || 1);
      setChildren(savedFilter.children || 0);
      setInfants(savedFilter.infant || 0);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setFrom, setTo, setAdults, setChildren, setInfants]);

  const handleLocationInputDone = useCallback(
    (value: string) => {
      setLocation(value);
      if (onValidate) onValidate({ locationError: false, dateError: false });
      saveToLocalStorage();
      if (onSearchChange) {
        const simulatedEvent = {
          target: { value } as EventTarget & HTMLInputElement,
        };
        onSearchChange(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
      }
      setActiveInput(null);
    },
    [onSearchChange, setLocation, onValidate]
  );

  const handleDatesChange = (startDate: Date | null, endDate: Date | null) => {
    setFrom(startDate);
    setTo(endDate);
    if (onValidate) onValidate({ locationError: false, dateError: false });
    saveToLocalStorage();

    if (startDate && endDate) {
      setActiveInput(null);
    }
  };

  const validateFields = () => {
    const locationError = !location;
    const dateError = !from || !to;

    if (onValidate) {
      onValidate({ locationError, dateError });
    }

    return !locationError && !dateError;
  };

  const handleSearchClick = () => {
    return validateFields();
  };

  useEffect(() => {
    // Función para manejar la tecla Escape
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setActiveInput(null);
      }
    };

    // Función para manejar clics fuera del modal
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Verificar si el clic se hizo fuera del modal abierto
      if (
        activeInput &&
        !target.closest(".location-input") &&
        !target.closest(".stay-dates-range-input") &&
        !target.closest(".guests-input")
      ) {
        setActiveInput(null);
      }
    };

    // Añadir los event listeners
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    // Limpiar los event listeners al desmontar el componente
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeInput]);

  return (
    <form className="relative flex rounded-full border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-800 p-1">
      <LocationInput
        className="flex-[1.5] location-input"
        autoFocus={activeInput === "location"}
        value={location}
        onInputDone={handleLocationInputDone}
        isOpen={activeInput === "location"}
        onClick={() => setActiveInput("location")}
      />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <StayDatesRangeInput
        className="flex-[1.1] stay-dates-range-input"
        onCheckoutSelected={onCheckoutSelected}
        onDatesChange={handleDatesChange}
        isOpen={activeInput === "dates"}
        onClick={() => setActiveInput("dates")}
      />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <GuestsInput
        className="flex-[1.5] guests-input"
        isOpen={activeInput === "guests"}
        onClick={() => setActiveInput("guests")}
        handleSearchClick={handleSearchClick}
      />
    </form>
  );
};

export default StaySearchForm;
