// GuestsContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface GuestsContextType {
  adults: number;
  children: number;
  infants: number;
  setAdults: (value: number) => void;
  setChildren: (value: number) => void;
  setInfants: (value: number) => void;
}

const GuestsContext = createContext<GuestsContextType | undefined>(undefined);

export const GuestsProvider: React.FC<{ children: ReactNode }> = ({ children: contextChildren }) => {
  const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");

  const [adults, setAdultsState] = useState<number>(savedFilter.adult || 1);
  const [children, setChildrenState] = useState<number>(savedFilter.children || 0);
  const [infants, setInfantsState] = useState<number>(savedFilter.infant || 0);

  useEffect(() => {
    // Guardar en localStorage cada vez que cambia un valor
    const savedFilter = {
      adult: adults,
      children: children,
      infant: infants,
    };
    localStorage.setItem("searchFilter", JSON.stringify(savedFilter));
  }, [adults, children, infants]);

  const setAdults = (value: number) => setAdultsState(Math.max(value, 1));
  const setChildren = (value: number) => setChildrenState(value);
  const setInfants = (value: number) => setInfantsState(value);

  return (
    <GuestsContext.Provider value={{ adults, children, infants, setAdults, setChildren, setInfants }}>
      {contextChildren}
    </GuestsContext.Provider>
  );
};

export const useGuestsContext = () => {
  const context = useContext(GuestsContext);
  if (context === undefined) {
    throw new Error("useGuestsContext debe usarse dentro de un GuestsProvider");
  }
  return context;
};
