import React, { FC, useEffect, useState } from "react";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";

export interface PageAddListing6Props {}

const PageAddListing6: FC<PageAddListing6Props> = () => {
  // Estado local para manejar el valor del textarea
  const [description, setDescription] = useState<string>("");

  // Cargar la descripción desde localStorage al iniciar
  useEffect(() => {
    const storedDescription = localStorage.getItem("pageAddListing6Description");
    if (storedDescription) {
      setDescription(storedDescription);
    }
  }, []);

  // Función para guardar la descripción en localStorage cuando se hace clic en "Continue"
  const handleSave = () => {
    localStorage.setItem("pageAddListing6Description", description);
  };

  // Manejar cambios en el textarea
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <CommonLayout
      index="06"
      backtHref="/add-listing-5"
      nextHref="/add-listing-7"
      onNext={handleSave} // Guardar el texto antes de continuar
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">
            Your place description for client
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Mention the best features of your accommodation, any special
            amenities like fast Wi-Fi or parking, as well as things you like
            about the neighborhood.
          </span>
        </div>

        <Textarea
          placeholder="..."
          rows={14}
          value={description} // Valor del textarea
          onChange={handleTextareaChange} // Manejar cambios
        />
      </>
    </CommonLayout>
  );
};

export default PageAddListing6;
