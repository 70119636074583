import { MapPinIcon } from "@heroicons/react/24/solid";
import Label from "components/Label/Label";
import { FC, useState, useEffect } from "react";
import axios from "axios";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";

export interface PageAddListing2Props {}

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const defaultPosition: LatLngExpression = [40.463667, -3.74922]; // España por defecto
  const [position, setPosition] = useState<LatLngExpression>(defaultPosition);
  const [zoom, setZoom] = useState<number>(5);

  const [formData, setFormData] = useState({
    country: "",
    city: "",
    street: "",
    state: "",
    postalCode: "",
    roomNumber: "",
  });

  // Cargar los datos del formulario desde localStorage al iniciar
  useEffect(() => {
    const storedData = localStorage.getItem("pageAddListing2");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFormData(parsedData.formData || formData); // Restaurar datos del formulario
      setPosition(parsedData.position || defaultPosition); // Restaurar posición del mapa
      setZoom(parsedData.zoom || 5); // Restaurar zoom del mapa
    }
  }, []);

  // Función para manejar los cambios en los inputs del formulario
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "country") {
      geocodeCountry(value);
    }
  };

  // Función para geocodificar el país y centrar el mapa
  const geocodeCountry = async (country: string) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(country)}`
      );
      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        setPosition([parseFloat(lat), parseFloat(lon)]);
        setZoom(6); // Zoom a nivel de país
      }
    } catch (error) {
      console.error("Error al geocodificar el país:", error);
    }
  };

  // Función para geocodificación inversa
  const reverseGeocode = async (lat: number, lon: number) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      if (response.data) {
        const addr = response.data.address;
        setFormData((prev) => ({
          ...prev,
          country: addr.country || "",
          city: addr.city || addr.town || "",
          street: `${addr.road || ""} ${addr.house_number || ""}`.trim(),
          state: addr.state || "",
          postalCode: addr.postcode || "",
        }));
      }
    } catch (error) {
      console.error("Error al obtener la dirección inversa:", error);
    }
  };

  // Componente para centrar el mapa cuando cambian las coordenadas o el zoom
  const RecenterMap = () => {
    const map = useMap();
    useEffect(() => {
      map.setView(position, zoom, { animate: true });
    }, [position, zoom, map]);
    return null;
  };

  // Función para guardar los datos en localStorage al pulsar "Continuar"
  const handleSaveData = () => {
    localStorage.setItem("pageAddListing2", JSON.stringify({ formData, position, zoom }));
  };

  return (
    <CommonLayout
      index="02"
      nextHref="/add-listing-3"
      backtHref="/add-listing-1"
      onNext={handleSaveData} // Guardamos los datos al pulsar "Continuar"
    >
      <>
        <h2 className="text-2xl font-semibold">Your place location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <ButtonSecondary>
            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span className="ml-3">Use current location</span>
          </ButtonSecondary>

          <FormItem label="Country/Region">
            <Select name="country" value={formData.country} onChange={handleInputChange}>
              <option value="">Select a country</option>
              <option value="Spain">Spain</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Thailand">Thailand</option>
              <option value="France">France</option>
              <option value="Singapore">Singapore</option>
              <option value="Japan">Japan</option>
              <option value="Korea">Korea</option>
            </Select>
          </FormItem>
          <FormItem label="Street">
            <Input name="street" value={formData.street} onChange={handleInputChange} placeholder="Street..." />
          </FormItem>
          <FormItem label="Room number (optional)">
            <Input name="roomNumber" value={formData.roomNumber} onChange={handleInputChange} placeholder="Room number..." />
          </FormItem>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <FormItem label="City">
              <Input name="city" value={formData.city} onChange={handleInputChange} />
            </FormItem>
            <FormItem label="State">
              <Input name="state" value={formData.state} onChange={handleInputChange} />
            </FormItem>
            <FormItem label="Postal code">
              <Input name="postalCode" value={formData.postalCode} onChange={handleInputChange} />
            </FormItem>
          </div>

          {/* Mapa */}
          <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              {formData.street}, {formData.city}, {formData.state}, {formData.country}
            </span>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <MapContainer center={position} zoom={zoom} style={{ width: "100%", height: "100%" }}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <RecenterMap />
                    <Marker
                      position={position}
                      draggable
                      eventHandlers={{
                        dragend: (e) => {
                          const newLatLng = e.target.getLatLng();
                          setPosition([newLatLng.lat, newLatLng.lng]);
                          reverseGeocode(newLatLng.lat, newLatLng.lng);
                        },
                      }}
                    />
                  </MapContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
