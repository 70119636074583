import {
  HeartIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
import isInViewport from "utils/isInViewport";
import { useAuth } from "contexts/AuthContext";  // Importamos el hook de autenticación

let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
}

const NAV: NavItem[] = [
  {
    name: "Explore",
    link: "/",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Wishlists",
    link: "/account-savelists",
    icon: HeartIcon,
  },
];

const FooterNav = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { isAuthenticated, user } = useAuth(); // Usamos el contexto para verificar autenticación y avatar

  const defaultAvatar = user?.avatar || "src/images/avatars/default-avatar.png"; // Avatar predeterminado o personalizado

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center">
        {/* MENU */}
        {NAV.map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              <item.icon
                className={`w-6 h-6 ${active ? "text-red-600" : ""}`}
              />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </Link>
          ) : null;
        })}

        {/* Autenticación: mostrar Log in o el avatar del usuario */}
        {!isAuthenticated ? (
          <Link
            to="/login" // Redirige a /login cuando no está autenticado
            className="flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90"
          >
            <UserCircleIcon className="w-6 h-6" />
            <span className="text-[11px] leading-none mt-1">Log in</span>
          </Link>
        ) : (
          <Link
            to="/account" // Redirige a /account cuando está autenticado
            className="flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90"
          >
            <img
              src={defaultAvatar} // Mostrar el avatar almacenado
              alt="User avatar"
              className="w-6 h-6 rounded-full"
            />
            <span className="text-[11px] leading-none mt-1">Account</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default FooterNav;
