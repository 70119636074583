import React, { useState, useEffect } from "react";
import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useLocationContext } from "contexts/LocationContext";

interface Props {
  tempLocation: string;
  setTempLocation: (value: string) => void;
  onChange?: (value: string) => void;
  className?: string;
  headingText?: string;
  setIsLocationValid?: (isValid: boolean) => void;
  onSelectSuggestion?: (value: string) => void;
}

const LocationInput: React.FC<Props> = ({
  tempLocation,
  setTempLocation,
  onChange = () => {},
  className = "",
  headingText = "Where to?",
  setIsLocationValid,
  onSelectSuggestion,
}) => {
  const { setLocation } = useLocationContext();
  const [inputValue, setInputValue] = useState(tempLocation || "");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  // Sincronizar inputValue con el estado temporal
  useEffect(() => {
    setInputValue(tempLocation);
  }, [tempLocation]);

  const mockLocations = ["Sevilla", "Málaga", "La Algaba, Sevilla"];

  // Filtrar sugerencias con debounce
  const fetchSuggestions = (input: string) => {
    const filtered = mockLocations.filter((loc) =>
      loc.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filtered);
  };

  // Manejar el cambio en el input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setInputValue(newValue);
    setTempLocation(newValue);

    if (setIsLocationValid) setIsLocationValid(false);

    // Manejar debounce para evitar múltiples llamadas a fetchSuggestions
    if (typingTimeout) clearTimeout(typingTimeout);
    const timeout = setTimeout(() => {
      fetchSuggestions(newValue);
    }, 500);
    setTypingTimeout(timeout);
  };

  // Manejar la selección de una sugerencia
  const handleSelectSuggestion = (item: string) => {
    setInputValue(item);
    setTempLocation(item);
    setLocation(item); // Guardar en el contexto al confirmar
    if (setIsLocationValid) setIsLocationValid(true);
    if (onSelectSuggestion) onSelectSuggestion(item);
    setSuggestions([]);
  };

  return (
    <div className={`${className}`}>
      <div className="p-5">
        <span className="font-semibold text-xl">{headingText}</span>
        <div className="relative mt-5">
          <input
            className="w-full bg-transparent border px-4 py-3 rounded-xl"
            placeholder="Destino"
            value={inputValue}
            onChange={handleInputChange}
          />
          <MagnifyingGlassIcon className="w-5 h-5 absolute right-4 top-1/2 transform -translate-y-1/2" />
        </div>
        {suggestions.length > 0 && (
          <div className="mt-3">
            {suggestions.map((item, index) => (
              <div
                key={index}
                className="py-2 flex items-center cursor-pointer"
                onClick={() => handleSelectSuggestion(item)}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500" />
                <span className="ml-3">{item}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationInput;
