import React, { useState, useRef, useEffect, FC, useCallback } from "react";
import ClearDataButton from "./ClearDataButton";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { useLocationContext } from "contexts/LocationContext";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  isOpen: boolean;
  onLocationSelected?: (selectedLocation: string) => void;
  onToggle?: (isOpen: boolean) => void;
  onOpen: () => void;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder,
  desc,
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  isOpen,
  onLocationSelected,
  onToggle,
  onOpen,
}) => {
  const { t } = useTranslation("locationInput");
  const containerRef = useRef<HTMLDivElement>(null); 
  const inputRef = useRef<HTMLInputElement>(null);

  const { location, locationCountry, setLocation, setLocationCountry } = useLocationContext();
  const [inputValue, setInputValue] = useState(location);
  const [suggestions, setSuggestions] = useState<string[]>(["Sevilla", "Málaga", "La Algaba, Sevilla"]);
  const [showPopover, setShowPopover] = useState(isOpen);

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setShowPopover(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setInputValue(location);
  }, [location]);

  const saveLocationToLocalStorage = (location: string, locationCountry: string) => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
    savedFilter.location = location;
    savedFilter.locationCountry = locationCountry;
    localStorage.setItem("searchFilter", JSON.stringify(savedFilter));
  };

  // Función para realizar el fetch de las ubicaciones al dejar de escribir o borrar
  const fetchSuggestions = (input: string) => {
    // Simulamos el fetch de las sugerencias, en este caso usamos las ubicaciones mockeadas
    const filteredSuggestions = ["Sevilla", "Málaga", "La Algaba, Sevilla"].filter(location =>
      location.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSelectLocation = (place: string) => {
    setInputValue(place);
    setLocation(place);
    setLocationCountry("Unknown"); // No estamos especificando el país, pero lo puedes ajustar si es necesario.
    saveLocationToLocalStorage(place, "Unknown");
    setShowPopover(false);

    if (onLocationSelected) {
      onLocationSelected(place);
    }
  };

  const handleClearLocation = useCallback(() => {
    setInputValue("");
    setLocation("");
    setLocationCountry("");
    saveLocationToLocalStorage("", "");
  }, [setLocation, setLocationCountry]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    // Cancelar el timeout previo si el usuario sigue escribiendo o borrando
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Setear un nuevo timeout que espere 500 ms después de que el usuario deje de escribir o borrar
    setTypingTimeout(
      setTimeout(() => {
        fetchSuggestions(value); // Fetch solo después de 500 ms sin escribir o borrar
      }, 500) // Retraso de 500ms
    );
  };

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
    if (onToggle) {
      onToggle(showPopover);
    }
  }, [showPopover, onToggle]);

  const renderSuggestions = () => (
    <>
      {suggestions.map((place, index) => (
        <span
          onClick={() => handleSelectLocation(place)}
          key={index}
          className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
        >
          <span className="block text-neutral-400">
            <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
          </span>
          <span className="block font-medium text-neutral-700 dark:text-neutral-200">
            {place}
          </span>
        </span>
      ))}
    </>
  );

  const handleClick = () => {
    onOpen();
    setShowPopover(true);
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={handleClick}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${showPopover ? "nc-hero-field-focused" : ""}`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
            placeholder={placeHolder || t("placeholder")}
            value={inputValue}
            autoFocus={showPopover}
            onChange={handleChange}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light">
            <span className="line-clamp-1">
              {!!inputValue ? t("placeholder") : t("description")}
            </span>
          </span>
          {inputValue && showPopover && <ClearDataButton onClick={handleClearLocation} />}
        </div>
      </div>

      {showPopover && (
        <div className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}></div>
      )}

      <Transition
        show={showPopover}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSuggestions()}
        </div>
      </Transition>
    </div>
  );
};

export default LocationInput;
