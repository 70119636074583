import React from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate } from "react-router-dom"; // Usar React Router para navegar

export interface CommonLayoutProps {
  index: string;
  nextHref: string;
  backtHref: string;
  nextBtnText?: string;
  children: React.ReactNode;
  onNext?: () => void; // Función opcional que se ejecuta antes de navegar
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "01",
  children,
  nextHref,
  nextBtnText,
  backtHref,
  onNext, // Recibimos la función de guardado opcional
}) => {
  const navigate = useNavigate(); // Hook de navegación de React Router

  const handleNextClick = () => {
    if (onNext) {
      onNext(); // Ejecuta la lógica de guardado
    }
    navigate(nextHref); // Navega a la siguiente página
  };

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 10
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          <ButtonSecondary onClick={() => navigate(backtHref)}>Go back</ButtonSecondary>
          <ButtonPrimary onClick={handleNextClick}>
            {nextBtnText || "Continue"}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
