import React, { createContext, useContext, useState } from "react";

interface ExperiencesFilterContextType {
  priceRange: number[];
  setPriceRange: (value: number[]) => void;
  selectedTypes: string[];
  setSelectedTypes: (types: string[]) => void;
  selectedTimeOfDay: string[];
  setSelectedTimeOfDay: (times: string[]) => void;
  isSaleOffActive: boolean;
  toggleSaleOffFilter: () => void;
}

const ExperiencesFilterContext = createContext<ExperiencesFilterContextType | undefined>(undefined);

export const ExperiencesFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [priceRange, setPriceRange] = useState<number[]>([0, 1000]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<string[]>([]);
  const [isSaleOffActive, setIsSaleOffActive] = useState(false);

  const toggleSaleOffFilter = () => {
    setIsSaleOffActive((prev) => !prev);
  };

  return (
    <ExperiencesFilterContext.Provider
      value={{
        priceRange,
        setPriceRange,
        selectedTypes,
        setSelectedTypes,
        selectedTimeOfDay,
        setSelectedTimeOfDay,
        isSaleOffActive,
        toggleSaleOffFilter,
      }}
    >
      {children}
    </ExperiencesFilterContext.Provider>
  );
};

export const useExperiencesFilterContext = (): ExperiencesFilterContextType => {
  const context = useContext(ExperiencesFilterContext);
  if (!context) {
    throw new Error("useExperiencesFilterContext must be used within an ExperiencesFilterProvider");
  }
  return context;
};
