import React, { Fragment, FC, useRef } from "react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import { Transition } from "@headlessui/react";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth2 from "components/DatePickerCustomHeaderTwoMonth2";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose?: () => void;
  onDateChange?: (startDate: Date | null, endDate: Date | null) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding--small ]",
  isOpen,
  onOpen,
  onClose,
  onDateChange,
}) => {
  const { t, i18n } = useTranslation("stayDatesRangeInput");
  const refContainer = useRef<HTMLDivElement>(null);
  const { from, to, setFrom, setTo } = useDatesContext();

  const currentLanguage = i18n.language;

  // Utilizamos useOutsideAlerter para cerrar al hacer clic fuera o presionar Escape
  useOutsideAlerter(refContainer, () => {
    if (isOpen && onClose) {
      onClose();
    }
  });

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [newStart, newEnd] = dates;
    setFrom(newStart);
    setTo(newEnd);

    if (onDateChange) {
      onDateChange(newStart, newEnd);
    }

    // Cierra el modal si se selecciona la fecha de salida
    if (newEnd && onClose) {
      onClose();
    }
  };

  const handleClearDates = () => {
    setFrom(null);
    setTo(null);
    if (onDateChange) {
      onDateChange(null, null);
    }
  };

  const renderInput = () => (
    <>
      <div className="text-neutral-300 dark:text-neutral-400">
        <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
      </div>
      <div className="flex-grow text-left">
        <span className="block xl:text-lg font-semibold">
          {from
            ? from.toLocaleDateString(currentLanguage, { month: "short", day: "2-digit" })
            : t("addDates")}
          {to
            ? " - " + to.toLocaleDateString(currentLanguage, { month: "short", day: "2-digit" })
            : ""}
        </span>
        <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
          {t("checkInCheckOut")}
        </span>
      </div>
    </>
  );

  return (
    <div ref={refContainer} className={`StayDatesRangeInput relative flex ${className}`}>
      <div
        className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none cursor-pointer ${
          isOpen ? "nc-hero-field-focused" : ""
        }`}
        onClick={onOpen}
      >
        {renderInput()}
        {from && isOpen && <ClearDataButton onClick={handleClearDates} />}
      </div>

      {isOpen && (
        <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
      )}

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
          <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
            <DatePicker
              selected={from}
              onChange={handleDateChange}
              startDate={from}
              endDate={to}
              selectsRange
              monthsShown={2}
              showPopperArrow={false}
              inline
              minDate={new Date()}
              renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth2 {...p} />}
              renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
            />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default StayDatesRangeInput;
