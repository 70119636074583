import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useState, useEffect } from "react";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListing3Props {}

const PageAddListing3: FC<PageAddListing3Props> = () => {
  const [formData, setFormData] = useState({
    acreage: "100",
    guests: 4,
    bedroom: 4,
    beds: 4,
    bathroom: 2,
    kitchen: 2,
  });

  // Cargar datos guardados de localStorage
  useEffect(() => {
    const savedData = localStorage.getItem("listingDataPage3");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // Guardar datos en localStorage al cambiar el formulario
  const handleSaveData = () => {
    localStorage.setItem("listingDataPage3", JSON.stringify(formData));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNumberChange = (name: string, value: number) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
      onNext={handleSaveData} // Guardar datos al hacer clic en continuar
    >
      <>
        <h2 className="text-2xl font-semibold">Size of your location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <FormItem label="Acreage (m2)">
            <Select
              name="acreage"
              value={formData.acreage}
              onChange={handleInputChange}
            >
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="400">400</option>
              <option value="500">500</option>
            </Select>
          </FormItem>
          <NcInputNumber
            label="Guests"
            defaultValue={formData.guests}
            onChange={(value) => handleNumberChange("guests", value)}
          />
          <NcInputNumber
            label="Bedroom"
            defaultValue={formData.bedroom}
            onChange={(value) => handleNumberChange("bedroom", value)}
          />
          <NcInputNumber
            label="Beds"
            defaultValue={formData.beds}
            onChange={(value) => handleNumberChange("beds", value)}
          />
          <NcInputNumber
            label="Bathroom"
            defaultValue={formData.bathroom}
            onChange={(value) => handleNumberChange("bathroom", value)}
          />
          <NcInputNumber
            label="Kitchen"
            defaultValue={formData.kitchen}
            onChange={(value) => handleNumberChange("kitchen", value)}
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
