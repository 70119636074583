// ButtonSubmit.tsx
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  href?: string;
  onSubmit?: () => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => boolean; // Cambia el tipo del evento a HTMLElement
}

const ButtonSubmit: FC<Props> = ({ href = "/search", onSubmit, onClick }) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick && !onClick(e)) {
      // Si la validación falla (onClick devuelve false), no navega
      return;
    }

    if (onSubmit) {
      onSubmit();
    }

    // Navegar después de una pequeña demora para asegurar que onSubmit se ejecuta
    setTimeout(() => {
      navigate(href);
    }, 100);
  };

  return (
    <button
      type="button"
      className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-400 hover:bg-primary-500 flex items-center justify-center text-neutral-50 focus:outline-none"
      onClick={handleClick}
    >
      <span className="mr-3 md:hidden">Search</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </button>
  );
};

export default ButtonSubmit;
