import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface ExperienceLocationContextType {
  location: string;
  locationCountry: string;
  setLocation: (location: string) => void;
  setLocationCountry: (country: string) => void;
}

const ExperienceLocationContext = createContext<ExperienceLocationContextType | undefined>(undefined);

export const ExperienceLocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");

  const [location, setLocationState] = useState<string>(savedFilter.location || "");
  const [locationCountry, setLocationCountryState] = useState<string>(savedFilter.locationCountry || "");

  const setLocation = (newLocation: string) => setLocationState(newLocation);
  const setLocationCountry = (newLocationCountry: string) => setLocationCountryState(newLocationCountry);

  useEffect(() => {
    const savedFilter = { location, locationCountry };
    localStorage.setItem("searchFilterExperiences", JSON.stringify(savedFilter));
  }, [location, locationCountry]);

  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");
      setLocationState(savedFilter.location || "");
      setLocationCountryState(savedFilter.locationCountry || "");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <ExperienceLocationContext.Provider value={{ location, locationCountry, setLocation, setLocationCountry }}>
      {children}
    </ExperienceLocationContext.Provider>
  );
};

export const useExperienceLocationContext = () => {
  const context = useContext(ExperienceLocationContext);
  if (!context) {
    throw new Error("useExperienceLocationContext must be used within an ExperienceLocationProvider");
  }
  return context;
};
