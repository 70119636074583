import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListing1Props {}

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const [formData, setFormData] = useState({
    propertyType: "",
    placeName: "",
    rentalForm: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("pageAddListing1");
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      localStorage.setItem("pageAddListing1", JSON.stringify(updatedData));
      return updatedData;
    });
  };

  // Función que guarda los datos antes de cambiar de página
  const handleNext = () => {
    localStorage.setItem("pageAddListing1", JSON.stringify(formData));
  };

  return (
    <CommonLayout
      index="01"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
      onNext={handleNext} // Pasamos la función de guardado
    >
      <>
        <h2 className="text-2xl font-semibold">Choosing listing categories</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          <FormItem
            label="Choose a property type"
            desc="Hotel: Professional hospitality businesses that usually have a unique style or theme defining their brand and decor"
          >
            <Select name="propertyType" value={formData.propertyType} onChange={handleInputChange}>
              <option value="Hotel">Hotel</option>
              <option value="Cottage">Cottage</option>
              <option value="Villa">Villa</option>
              <option value="Cabin">Cabin</option>
              <option value="Farm stay">Farm stay</option>
              <option value="Houseboat">Houseboat</option>
              <option value="Lighthouse">Lighthouse</option>
            </Select>
          </FormItem>
          <FormItem label="Place name" desc="A catchy name usually includes: House name + Room name + Featured property + Tourist destination">
            <Input name="placeName" value={formData.placeName} onChange={handleInputChange} placeholder="Place name" />
          </FormItem>
          <FormItem label="Rental form" desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included.">
            <Select name="rentalForm" value={formData.rentalForm} onChange={handleInputChange}>
              <option value="Entire place">Entire place</option>
              <option value="Private room">Private room</option>
              <option value="Share room">Share room</option>
            </Select>
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
