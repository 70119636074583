import { FC, useState, useEffect, useRef } from "react";
import { Marker, useMap } from "react-leaflet";
import { DivIcon } from "leaflet";
import L, { LeafletMouseEvent } from "leaflet"; // Importa LeafletMouseEvent
import "leaflet.markercluster"; // Importamos markercluster
import { CarDataType, ExperiencesDataType, StayDataType } from "data/types";
import StayCard from "components/StayCard/StayCard"; // Importamos el componente de StayCard
import styles from "./MapContainer.module.css";

interface CustomMarkerLayerProps {
  currentHoverID: string | number;
  DEMO_DATA: (CarDataType | ExperiencesDataType | StayDataType)[];
  listingType: "car" | "experiences" | "stay";
}

const CustomMarkerLayer: FC<CustomMarkerLayerProps> = ({
  currentHoverID,
  DEMO_DATA,
  listingType,
}) => {
  const [selectedID, setSelectedID] = useState<string | number | null>(null);
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);
  const map = useMap(); // Accedemos al mapa de Leaflet
  const cardRef = useRef<HTMLDivElement | null>(null); // Ref para el contenedor de la tarjeta

  // Función para obtener la posición en píxeles de las coordenadas
  const getPixelPosition = (lat: number, lng: number) => {
    const point = map.latLngToContainerPoint([lat, lng]);
    return { x: point.x, y: point.y };
  };

  // Función para manejar clics en el mapa (fuera de la tarjeta)
  const handleMapClick = (e: LeafletMouseEvent) => {
    if (cardRef.current && !cardRef.current.contains(e.originalEvent.target as Node)) {
      // Solo cierra la tarjeta si el clic fue fuera de ella
      setSelectedID(null);
    }
  };

  // Controla si se debe permitir o no el movimiento del mapa
  useEffect(() => {
    if (selectedID) {
      map.dragging.disable(); // Deshabilita el arrastre del mapa cuando una tarjeta está abierta
      map.scrollWheelZoom.disable(); // Deshabilita el zoom con la rueda del mouse
      map.doubleClickZoom.disable(); // Deshabilita el zoom con doble clic
      map.on("click", handleMapClick); // Escucha clics fuera de la tarjeta
    } else {
      map.dragging.enable(); // Habilita el movimiento del mapa cuando no hay tarjeta abierta
      map.scrollWheelZoom.enable(); // Habilita el zoom con la rueda del mouse
      map.doubleClickZoom.enable(); // Habilita el zoom con doble clic si no hay tarjeta abierta
      map.off("click", handleMapClick); // Desactiva el evento de clic en el mapa
    }

    // Cleanup cuando el componente se desmonte o si cambia el estado de `selectedID`
    return () => {
      map.dragging.enable();
      map.scrollWheelZoom.enable();
      map.doubleClickZoom.enable();
      map.off("click", handleMapClick);
    };
  }, [selectedID, map]);

  const handleMarkerClick = (item: StayDataType | CarDataType | ExperiencesDataType) => {
    setSelectedID((prevID) => {
      const newID = prevID === item.id ? null : item.id;
      if (newID !== null) {
        // Obtenemos la posición en píxeles del marcador seleccionado
        const pixelPosition = getPixelPosition(item.map.lat, item.map.lng);
        setPosition(pixelPosition); // Actualizamos la posición en píxeles
      }
      return newID;
    });
  };

  useEffect(() => {
    const markerClusterGroup = L.markerClusterGroup(); // Crear el grupo de clústeres

    DEMO_DATA.forEach((item) => {
      const { lat, lng } = item.map;

      // Icono personalizado de Leaflet solo para mostrar el precio
      const customIcon = new DivIcon({
        html: `<div class="${styles.customMarker}"><div class="${styles.priceBox}">${item.price}</div></div>`,
        className: styles.customIcon,
      });

      // Crear un marcador
      const marker = L.marker([lat, lng], {
        icon: customIcon,
      });

      // Agregar evento de clic en el marcador
      marker.on('click', () => handleMarkerClick(item));

      // Añadir el marcador al grupo de clústeres
      markerClusterGroup.addLayer(marker);
    });

    // Añadir el grupo de clústeres al mapa
    map.addLayer(markerClusterGroup);

    // Limpiar el grupo de clústeres cuando se desmonta el componente
    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [DEMO_DATA, map]);

  return (
    <>
      {/* Mostrar la StayCard del alojamiento si está seleccionada */}
      {selectedID && position && (
        <div
          ref={cardRef} // Asigna la referencia al contenedor de la tarjeta
          style={{
            position: "absolute",
            zIndex: 1000,
            left: `${position.x}px`, // Posición dinámica
            top: `${position.y}px`,  // Posición dinámica
          }}
          onClick={(e) => e.stopPropagation()} // Prevenimos que el clic dentro de la tarjeta cierre la tarjeta
        >
          <StayCard
            data={DEMO_DATA.find((item) => item.id === selectedID) as StayDataType} // Pasamos los datos completos del alojamiento
            size="small" // Definimos el tamaño de la tarjeta si es necesario
            className={styles.stayCard} // Clase CSS para personalización
          />
        </div>
      )}
    </>
  );
};

export default CustomMarkerLayer;
