"use client";

import React, { FC, useState, useEffect, startTransition } from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
import { useTranslation } from "react-i18next";

export type SearchTab = "Stays" | "Experiences";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Stays" | "Experiences";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Stays",
  currentPage,
}) => {
  const { t } = useTranslation('heroSearchForm');
  const tabs: SearchTab[] = ["Stays", "Experiences"];
  const [tabActive, setTabActive] = useState<SearchTab>(() => {
    const savedTab = localStorage.getItem('tabActive');
    return savedTab ? (savedTab as SearchTab) : currentTab;
  });

  // Guardar el tab activo en localStorage
  useEffect(() => {
    localStorage.setItem('tabActive', tabActive);
  }, [tabActive]);

  // Manejar el clic en las pestañas y usar startTransition para rendimiento
  const handleTabClick = (tab: SearchTab) => {
    startTransition(() => {
      setTabActive(tab);
    });
  };

  // Listener para cambios en el localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const savedTab = localStorage.getItem('tabActive');
      if (savedTab) {
        setTabActive(savedTab as SearchTab);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => handleTabClick(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{t(tab.toLowerCase())}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "Stays":
        return <StaySearchForm />;
      case "Experiences":
        return <ExperiencesSearchForm />;
      default:
        return null;
    }
  };

  return (
    <div className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}>
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
