import React, { createContext, useContext, useEffect, useState } from "react";

interface DatesContextType {
  from: Date | null;
  to: Date | null;
  setFrom: (date: Date | null) => void;
  setTo: (date: Date | null) => void;
}

const DatesContext = createContext<DatesContextType | undefined>(undefined);

export const DatesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [from, setFrom] = useState<Date | null>(() => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
    return savedFilter.from ? new Date(savedFilter.from) : null;
  });

  const [to, setTo] = useState<Date | null>(() => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
    return savedFilter.to ? new Date(savedFilter.to) : null;
  });

  useEffect(() => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
    savedFilter.from = from ? from.toISOString() : null;
    savedFilter.to = to ? to.toISOString() : null;
    localStorage.setItem("searchFilter", JSON.stringify(savedFilter));
  }, [from, to]);

  return (
    <DatesContext.Provider value={{ from, to, setFrom, setTo }}>
      {children}
    </DatesContext.Provider>
  );
};

export const useDatesContext = () => {
  const context = useContext(DatesContext);
  if (!context) {
    throw new Error("useDatesContext must be used within a DatesProvider");
  }
  return context;
};
