import React, { FC, useState, useEffect } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import CommonLayout from "./CommonLayout";

type Amenities = {
  Wifi: boolean;
  Internet: boolean;
  TV: boolean;
  "Air conditioning": boolean;
  Fan: boolean;
  "Private entrance": boolean;
  Dryer: boolean;
  Heater: boolean;
  "Washing machine": boolean;
  Detergent: boolean;
  "Clothes dryer": boolean;
  "Baby cot": boolean;
  Desk: boolean;
  Wardrobe: boolean;
  "Cloth hook": boolean;
  Fridge: boolean;
  "Extra cushion": boolean;
  "Gas stove": boolean;
  "Toilet paper": boolean;
  "Free toiletries": boolean;
  "Makeup table": boolean;
  "Hot pot": boolean;
  "Bathroom heaters": boolean;
  Kettle: boolean;
  Dishwasher: boolean;
  "BBQ grill": boolean;
  Toaster: boolean;
  Towel: boolean;
  "Dining table": boolean;
  "Fire siren": boolean;
  "Fire extinguisher": boolean;
  "Anti-theft key": boolean;
  "Safe vault": boolean;
};

const PageAddListing4: FC = () => {
  const initialAmenities: Amenities = {
    Wifi: true,
    Internet: false,
    TV: true,
    "Air conditioning": false,
    Fan: false,
    "Private entrance": false,
    Dryer: true,
    Heater: false,
    "Washing machine": false,
    Detergent: true,
    "Clothes dryer": false,
    "Baby cot": false,
    Desk: true,
    Wardrobe: false,
    "Cloth hook": false,
    Fridge: false,
    "Extra cushion": true,
    "Gas stove": false,
    "Toilet paper": false,
    "Free toiletries": true,
    "Makeup table": false,
    "Hot pot": false,
    "Bathroom heaters": false,
    Kettle: true,
    Dishwasher: false,
    "BBQ grill": true,
    Toaster: true,
    Towel: false,
    "Dining table": false,
    "Fire siren": true,
    "Fire extinguisher": false,
    "Anti-theft key": false,
    "Safe vault": false,
  };

  const [amenities, setAmenities] = useState<Amenities>(() => {
    const savedAmenities = localStorage.getItem("listingDataPage4");
    return savedAmenities ? JSON.parse(savedAmenities) : initialAmenities;
  });

  // Guardar los datos en localStorage cuando se actualicen los amenities
  useEffect(() => {
    localStorage.setItem("listingDataPage4", JSON.stringify(amenities));
  }, [amenities]);

  const handleCheckboxChange = (name: keyof Amenities, checked: boolean) => {
    setAmenities((prevAmenities: Amenities) => ({
      ...prevAmenities,
      [name]: checked,
    }));
  };

  return (
    <CommonLayout
      index="04"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Amenities</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on amenities
            criteria.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <div className="space-y-8">
          {/* General amenities */}
          <div>
            <label className="text-lg font-semibold">General amenities</label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              <Checkbox
                label="Wifi"
                name="Wifi"
                checked={amenities.Wifi}
                onChange={(checked) => handleCheckboxChange("Wifi", checked)}
              />
              <Checkbox
                label="Internet"
                name="Internet"
                checked={amenities.Internet}
                onChange={(checked) => handleCheckboxChange("Internet", checked)}
              />
              <Checkbox
                label="TV"
                name="TV"
                checked={amenities.TV}
                onChange={(checked) => handleCheckboxChange("TV", checked)}
              />
              <Checkbox
                label="Air conditioning"
                name="Air conditioning"
                checked={amenities["Air conditioning"]}
                onChange={(checked) =>
                  handleCheckboxChange("Air conditioning", checked)
                }
              />
              <Checkbox
                label="Fan"
                name="Fan"
                checked={amenities.Fan}
                onChange={(checked) => handleCheckboxChange("Fan", checked)}
              />
              <Checkbox
                label="Private entrance"
                name="Private entrance"
                checked={amenities["Private entrance"]}
                onChange={(checked) =>
                  handleCheckboxChange("Private entrance", checked)
                }
              />
              <Checkbox
                label="Dryer"
                name="Dryer"
                checked={amenities.Dryer}
                onChange={(checked) => handleCheckboxChange("Dryer", checked)}
              />
              <Checkbox
                label="Heater"
                name="Heater"
                checked={amenities.Heater}
                onChange={(checked) => handleCheckboxChange("Heater", checked)}
              />
              <Checkbox
                label="Washing machine"
                name="Washing machine"
                checked={amenities["Washing machine"]}
                onChange={(checked) =>
                  handleCheckboxChange("Washing machine", checked)
                }
              />
              <Checkbox
                label="Detergent"
                name="Detergent"
                checked={amenities.Detergent}
                onChange={(checked) => handleCheckboxChange("Detergent", checked)}
              />
              <Checkbox
                label="Clothes dryer"
                name="Clothes dryer"
                checked={amenities["Clothes dryer"]}
                onChange={(checked) =>
                  handleCheckboxChange("Clothes dryer", checked)
                }
              />
              <Checkbox
                label="Baby cot"
                name="Baby cot"
                checked={amenities["Baby cot"]}
                onChange={(checked) => handleCheckboxChange("Baby cot", checked)}
              />
              <Checkbox
                label="Desk"
                name="Desk"
                checked={amenities.Desk}
                onChange={(checked) => handleCheckboxChange("Desk", checked)}
              />
              <Checkbox
                label="Fridge"
                name="Fridge"
                checked={amenities.Fridge}
                onChange={(checked) => handleCheckboxChange("Fridge", checked)}
              />
            </div>
          </div>

          {/* Other amenities */}
          <div>
            <label className="text-lg font-semibold">Other amenities</label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              <Checkbox
                label="Wardrobe"
                name="Wardrobe"
                checked={amenities.Wardrobe}
                onChange={(checked) => handleCheckboxChange("Wardrobe", checked)}
              />
              <Checkbox
                label="Cloth hook"
                name="Cloth hook"
                checked={amenities["Cloth hook"]}
                onChange={(checked) => handleCheckboxChange("Cloth hook", checked)}
              />
              <Checkbox
                label="Extra cushion"
                name="Extra cushion"
                checked={amenities["Extra cushion"]}
                onChange={(checked) =>
                  handleCheckboxChange("Extra cushion", checked)
                }
              />
              <Checkbox
                label="Gas stove"
                name="Gas stove"
                checked={amenities["Gas stove"]}
                onChange={(checked) => handleCheckboxChange("Gas stove", checked)}
              />
              <Checkbox
                label="Toilet paper"
                name="Toilet paper"
                checked={amenities["Toilet paper"]}
                onChange={(checked) =>
                  handleCheckboxChange("Toilet paper", checked)
                }
              />
              <Checkbox
                label="Free toiletries"
                name="Free toiletries"
                checked={amenities["Free toiletries"]}
                onChange={(checked) =>
                  handleCheckboxChange("Free toiletries", checked)
                }
              />
              <Checkbox
                label="Makeup table"
                name="Makeup table"
                checked={amenities["Makeup table"]}
                onChange={(checked) =>
                  handleCheckboxChange("Makeup table", checked)
                }
              />
              <Checkbox
                label="Hot pot"
                name="Hot pot"
                checked={amenities["Hot pot"]}
                onChange={(checked) => handleCheckboxChange("Hot pot", checked)}
              />
              <Checkbox
                label="Bathroom heaters"
                name="Bathroom heaters"
                checked={amenities["Bathroom heaters"]}
                onChange={(checked) =>
                  handleCheckboxChange("Bathroom heaters", checked)
                }
              />
              <Checkbox
                label="Kettle"
                name="Kettle"
                checked={amenities.Kettle}
                onChange={(checked) => handleCheckboxChange("Kettle", checked)}
              />
              <Checkbox
                label="Dishwasher"
                name="Dishwasher"
                checked={amenities.Dishwasher}
                onChange={(checked) =>
                  handleCheckboxChange("Dishwasher", checked)
                }
              />
              <Checkbox
                label="BBQ grill"
                name="BBQ grill"
                checked={amenities["BBQ grill"]}
                onChange={(checked) => handleCheckboxChange("BBQ grill", checked)}
              />
              <Checkbox
                label="Toaster"
                name="Toaster"
                checked={amenities.Toaster}
                onChange={(checked) => handleCheckboxChange("Toaster", checked)}
              />
              <Checkbox
                label="Towel"
                name="Towel"
                checked={amenities.Towel}
                onChange={(checked) => handleCheckboxChange("Towel", checked)}
              />
              <Checkbox
                label="Dining table"
                name="Dining table"
                checked={amenities["Dining table"]}
                onChange={(checked) =>
                  handleCheckboxChange("Dining table", checked)
                }
              />
            </div>
          </div>

          {/* Safe amenities */}
          <div>
            <label className="text-lg font-semibold">Safe amenities</label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              <Checkbox
                label="Fire siren"
                name="Fire siren"
                checked={amenities["Fire siren"]}
                onChange={(checked) =>
                  handleCheckboxChange("Fire siren", checked)
                }
              />
              <Checkbox
                label="Fire extinguisher"
                name="Fire extinguisher"
                checked={amenities["Fire extinguisher"]}
                onChange={(checked) =>
                  handleCheckboxChange("Fire extinguisher", checked)
                }
              />
              <Checkbox
                label="Anti-theft key"
                name="Anti-theft key"
                checked={amenities["Anti-theft key"]}
                onChange={(checked) =>
                  handleCheckboxChange("Anti-theft key", checked)
                }
              />
              <Checkbox
                label="Safe vault"
                name="Safe vault"
                checked={amenities["Safe vault"]}
                onChange={(checked) => handleCheckboxChange("Safe vault", checked)}
              />
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
