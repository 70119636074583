import React, { Fragment, useState, startTransition, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import ButtonSubmit from "./ButtonSubmit";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./DatesRangeInput";
import GuestsInput from "./GuestsInput";
import LocationInputExperiences from "./LocationInputExperiences";
import ExperienceDatesRangeInput from "./ExperienceDatesRangeInput";
import ExperienceGuestsInput from "./ExperienceGuestsInput";
import { useTranslation } from "react-i18next";
import { useLocationContext } from "contexts/LocationContext";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";
import Logo from "shared/Logo/LogoMobileSearch";

const HeroSearchForm2Mobile = () => {
  const { t, i18n } = useTranslation("heroSearchForm2Mobile");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentTab, setCurrentTab] = useState<"stay" | "experience">("stay");
  const [isLocationValid, setIsLocationValid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { pathname } = useLocation();

  // Estados temporales para `stay`
  const [tempLocation, setTempLocation] = useState<string>("");
  const [tempStartDate, setTempStartDate] = useState<Date | null>(null);
  const [tempEndDate, setTempEndDate] = useState<Date | null>(null);
  const [tempAdults, setTempAdults] = useState(1);
  const [tempChildren, setTempChildren] = useState(0);
  const [tempInfants, setTempInfants] = useState(0);

  // Estados temporales para `experience`
  const [tempExperienceLocation, setTempExperienceLocation] = useState<string>("");
  const [tempExperienceStartDate, setTempExperienceStartDate] = useState<Date | null>(null);
  const [tempExperienceEndDate, setTempExperienceEndDate] = useState<Date | null>(null);
  const [tempExpAdults, setTempExpAdults] = useState(1);
  const [tempExpChildren, setTempExpChildren] = useState(0);
  const [tempExpInfants, setTempExpInfants] = useState(0);

  const isStayTab = currentTab === "stay";
  const isExperienceTab = currentTab === "experience";

  const [isDatesValid, setIsDatesValid] = useState(false);

  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");

  // Contextos para `stay`
  const { location, setLocation } = useLocationContext();
  const { from, to, setFrom, setTo } = useDatesContext();
  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();

  // Contextos para `experience`
  const { location: experienceLocation, setLocation: setExperienceLocation } = useExperienceLocationContext();
  const { from: experienceFrom, to: experienceTo, setFrom: setExperienceFrom, setTo: setExperienceTo } = useExperienceDatesContext();
  const { adults: expAdults, children: expChildren, infants: expInfants, setAdults: setExpAdults, setChildren: setExpChildren, setInfants: setExpInfants } = useExperienceGuestsContext();

  // Detectar la página actual y actualizar `currentTab`
  useEffect(() => {
    if (pathname.includes("/listing-experiences")) {
      setCurrentTab("experience");
    } else if (pathname.includes("/listing-stay")) {
      setCurrentTab("stay");
    }
  }, [pathname]);

  // Validar fechas seleccionadas automáticamente al cambiar `from` y `to`.
  useEffect(() => {
    if (isStayTab) {
      setIsDatesValid(!!from && !!to); // Requiere ambas fechas
    } else {
      setIsDatesValid(!!experienceFrom && !!experienceTo); // Requiere ambas fechas
    }
  }, [from, to, experienceFrom, experienceTo, isStayTab]);  

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  /*
  Bloque Lógica Botón Continuar
  */

  const handleContinue = () => {

    // Paso 1: Indica que estamos en Input de Location
    if (currentStep === 1) {
      if (!isLocationValid) { //Si no es válida la ubicación
        setShowWarning(true);
        return;
      }

      // Guardar la ubicación seleccionada (usando el estado temporal)
      const locationToSave = selectedSuggestion || (isStayTab ? tempLocation : tempExperienceLocation);
      if (isStayTab) {
        setLocation(locationToSave);
      } else {
        setExperienceLocation(locationToSave);
      }

      setCurrentStep(2);
      setShowWarning(false);
    }
    else if (currentStep === 2) {
      // Verificar que ambas fechas estén seleccionadas
      const isCheckOutSelected = isStayTab ? !!tempEndDate : !!tempExperienceEndDate;
    
      if (!isDatesValid || !isCheckOutSelected) {
        setShowWarning(true);
        return;
      }
    
      // Guardar las fechas seleccionadas al contexto principal
      if (isStayTab) {
        setFrom(tempStartDate);
        setTo(tempEndDate);
      } else {
        setExperienceFrom(tempExperienceStartDate);
        setExperienceTo(tempExperienceEndDate);
      }
    
      setCurrentStep(3);
      setShowWarning(false);
    }
    
    else if (currentStep === 3) {
      // Guardar los datos de huéspedes al contexto principal (usando los estados temporales)
      if (isStayTab) {
        setAdults(tempAdults);
        setChildren(tempChildren);
        setInfants(tempInfants);
      } else {
        setExpAdults(tempExpAdults);
        setExpChildren(tempExpChildren);
        setExpInfants(tempExpInfants);
      }

      // Finalizar y navegar
      closeModal();
      setCurrentStep(1);
      navigate(currentTab === "stay" ? "/listing-stay" : "/listing-experiences");
    }
  };

  const handleClearAll = () => {
    setCurrentStep(1);
    setIsLocationValid(false);
    setShowWarning(false);

    if (currentTab === "stay") {
      startTransition(() => {
        // Limpiar contextos
        setLocation("");
        setFrom(null);
        setTo(null);
        setAdults(1);
        setChildren(0);
        setInfants(0);

        // Limpiar estados temporales
        setTempLocation("");
        setTempStartDate(null);
        setTempEndDate(null);
        setTempAdults(1);
        setTempChildren(0);
        setTempInfants(0);
      });
    } else {
      startTransition(() => {
        // Limpiar contextos
        setExperienceLocation("");
        setExperienceFrom(null);
        setExperienceTo(null);
        setExpAdults(1);
        setExpChildren(0);
        setExpInfants(0);

        // Limpiar estados temporales
        setTempExperienceLocation("");
        setTempExperienceStartDate(null);
        setTempExperienceEndDate(null);
        setTempExpAdults(1);
        setTempExpChildren(0);
        setTempExpInfants(0);
      });
    }
  };


  const dateFormat: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short" };

  // Obtener checkIn
  const checkIn = isStayTab
    ? from
      ? from.toLocaleDateString(i18n.language, dateFormat)
      : t("anyWeek")
    : experienceFrom
      ? experienceFrom.toLocaleDateString(i18n.language, dateFormat)
      : t("anyWeek");

  // Obtener checkOut solo si hay fechas seleccionadas
  const checkOut = (isStayTab && from && to) || (experienceFrom && experienceTo)
    ? isStayTab
      ? to?.toLocaleDateString(i18n.language, dateFormat)
      : experienceTo?.toLocaleDateString(i18n.language, dateFormat)
    : "";

  // Resultado final para mostrar en la barra
  const dateText = checkOut ? `${checkIn} - ${checkOut}` : checkIn;

  const totalGuests = isStayTab ? adults + children + infants : expAdults + expChildren + expInfants;
  const guestsLabel = `${totalGuests} ${t("guests", { ns: "guestsInput" })}`;
  const selectedLocation = isStayTab ? location || t("whereTo") : experienceLocation || t("whereTo");

  const renderStepContent = () => {
    if (isStayTab) {
      switch (currentStep) {
        case 1:
          return (
            <LocationInput
              headingText={t("whereTo")}
              tempLocation={tempLocation}
              setTempLocation={setTempLocation}
              setIsLocationValid={setIsLocationValid}
              onSelectSuggestion={(value) => setTempLocation(value)}
            />

          );
        case 2:
          return (
            <StayDatesRangeInput
              onChange={(start, end) => {
                setTempStartDate(start);
                setTempEndDate(end);
                setIsDatesValid(!!start && !!end);
              }}
              tempStartDate={tempStartDate}
              tempEndDate={tempEndDate}
              setTempStartDate={setTempStartDate}
              setTempEndDate={setTempEndDate}
              setIsDatesValid={setIsDatesValid}
            />
          );
        case 3:
          return (
            <GuestsInput
              onChange={({ guestAdults, guestChildren, guestInfants }) => {
                setTempAdults(guestAdults ?? 1);
                setTempChildren(guestChildren ?? 0);
                setTempInfants(guestInfants ?? 0);
              }}
              tempAdults={tempAdults}
              tempChildren={tempChildren}
              tempInfants={tempInfants}
              setTempAdults={setTempAdults}
              setTempChildren={setTempChildren}
              setTempInfants={setTempInfants}
            />
          );
        default:
          return null;
      }
    } else {
      switch (currentStep) {
        case 1:
          return (
            <LocationInputExperiences
            headingText="Where to?"
            tempExperienceLocation={tempExperienceLocation}
            setTempExperienceLocation={setTempExperienceLocation}
            setIsLocationValid={setIsLocationValid}
            onSelectSuggestion={(value) => setTempExperienceLocation(value)}
          />          

          );
        case 2:
          return (
            <ExperienceDatesRangeInput
              onChange={(start, end) => {
                setTempExperienceStartDate(start);
                setTempExperienceEndDate(end);
                setIsDatesValid(!!start && !!end);
              }}
              tempStartDate={tempExperienceStartDate}
              tempEndDate={tempExperienceEndDate}
              setTempStartDate={setTempExperienceStartDate}
              setTempEndDate={setTempExperienceEndDate}
              setIsDatesValid={setIsDatesValid}
            />
          );
        case 3:
          return (
            <ExperienceGuestsInput
              onChange={({ guestAdults, guestChildren, guestInfants }) => {
                setTempExpAdults(guestAdults ?? 1);
                setTempExpChildren(guestChildren ?? 0);
                setTempExpInfants(guestInfants ?? 0);
              }}
              tempAdults={tempExpAdults}
              tempChildren={tempExpChildren}
              tempInfants={tempExpInfants}
              setTempAdults={setTempExpAdults}
              setTempChildren={setTempExpChildren}
              setTempInfants={setTempExpInfants}
            />
          );
        default:
          return null;
      }
    }
  };

  const renderButtonOpenModal = () => (
    <button
      onClick={openModal}
      className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 rounded-full shadow-lg"
      data-testid="search-bar-button"
    >
      {/* Logo a la izquierda */}
      <div
        className="flex items-center absolute left-4 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation(); // Evitar la apertura del modal
          navigate("/"); // Navegar a la página principal
        }}
        data-testid="search-bar-logo"
      >
        <Logo className="" />
      </div>

      {/* Información de location, dates y guests en el centro */}
      <div className="flex flex-col items-center justify-center mx-auto" data-testid="search-bar-content">
        <span className="block font-semibold text-base lg:text-lg truncate" data-testid="search-bar-location">
          {selectedLocation}
        </span>
        <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-1" data-testid="search-bar-dates-guests">
            {`${dateText} • ${guestsLabel}`}
          </span>
        </div>
      </div>

      {/* Icono de lupa a la derecha */}
      <div className="flex items-center absolute right-4">
        <MagnifyingGlassIcon className="w-5 h-5" data-testid="search-bar-icon" />
      </div>
    </button>
  );

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="HeroSearchFormMobile__Dialog relative z-max" onClose={closeModal}>
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between">
                  <button
                    onClick={closeModal}
                    className="absolute top-4 right-4 p-2 rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 focus:outline-none"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-neutral-700 dark:text-neutral-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>

                  <Tab.Group
                    selectedIndex={currentTab === "stay" ? 0 : currentTab === "experience" ? 1 : 0}
                    onChange={(index) => {
                      startTransition(() => {
                        setCurrentTab(index === 0 ? "stay" : "experience");
                      });
                    }}
                    manual
                  >
                    <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                      {[t("stay"), t("experiences")].map((item, index) => (
                        <Tab key={index} as={Fragment}>
                          {({ selected }) => (
                            <div className={`relative ${selected ? "text-black dark:text-white" : ""}`}>
                              {item}
                              {selected && <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>}
                            </div>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="flex-1 overflow-y-auto py-4">
                      <Tab.Panel>{renderStepContent()}</Tab.Panel>
                      <Tab.Panel>{renderStepContent()}</Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>

                  <div className="px-4 py-3 flex justify-between">
                    <button className="underline font-semibold" onClick={handleClearAll}>
                      {t("clearAll")}
                    </button>
                    <ButtonSubmit
                      onClick={handleContinue}
                      text={currentStep === 3 ? t("search") : t("continue")}
                      href={currentTab === "stay" ? "/listing-stay" : "/listing-experiences"}
                      disabled={(currentStep === 1 && !isLocationValid) || (currentStep === 2 && !isDatesValid)}
                    />
                  </div>

                  {showWarning && (
                    <div className="text-red-500 text-center mt-2">
                      {t("Please select a location from the suggestions to continue.")}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
