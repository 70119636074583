import React, { useState, useRef, useEffect, FC, useCallback } from "react";
import ClearDataButton from "./ClearDataButton";
import { MapPinIcon } from "@heroicons/react/24/outline";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useTranslation } from "react-i18next";
import { useLocationContext } from "contexts/LocationContext";

export interface LocationInputProps {
  value?: string;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  isOpen?: boolean; // Control de apertura externa del popover
  onClick?: () => void; // Evento al hacer clic en el input
}

const LocationInput: FC<LocationInputProps> = ({
  value = "",
  autoFocus = false,
  onInputDone,
  placeHolder,
  desc,
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  isOpen: externalIsOpen = false,
  onClick,
}) => {
  const { t } = useTranslation("locationInput");
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { location: contextLocation, setLocation } = useLocationContext();

  const [showPopover, setShowPopover] = useState(externalIsOpen);
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  // Sincronizar showPopover con externalIsOpen
  useEffect(() => {
    setShowPopover(externalIsOpen);
  }, [externalIsOpen]);

  // Sincronizar inputValue con el contexto de ubicación
  useEffect(() => {
    setInputValue(contextLocation);
  }, [contextLocation]);

  // Guardar la ubicación en localStorage
  const saveLocationToLocalStorage = (location: string) => {
    const savedFilter = JSON.parse(localStorage.getItem("searchFilter") || "{}");
    savedFilter.location = location;
    localStorage.setItem("searchFilter", JSON.stringify(savedFilter));
  };

  // Ubicaciones predefinidas (mocked)
  const mockLocations = [
    "Sevilla",
    "Málaga",
    "La Algaba, Sevilla",
  ];

  // Filtrar las ubicaciones basadas en el texto ingresado
  const fetchSuggestions = (input: string) => {
    const filteredSuggestions = mockLocations.filter(location =>
      location.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  // Manejar la selección de una ubicación
  const handleSelectLocation = (item: string) => {
    setLocation(item);
    setInputValue(item);
    saveLocationToLocalStorage(item);
    setShowPopover(false);
    if (onInputDone) {
      onInputDone(item);
    }
  };

  // Limpiar la ubicación
  const handleClearLocation = useCallback(() => {
    setInputValue("");
    setLocation("");
    saveLocationToLocalStorage("");
  }, [setLocation]);

  // Manejar el cambio en el input con debounce
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setInputValue(newValue);

    // Cancelar el timeout previo si el usuario sigue escribiendo
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Establecer un nuevo timeout que espere 500 ms después de que el usuario deje de escribir
    setTypingTimeout(
      setTimeout(() => {
        fetchSuggestions(newValue); // Realiza el filtrado después de 500 ms sin escribir
      }, 500) // Retraso de 500ms
    );
  };

  // Detectar clics fuera del contenedor para cerrar el popover
  useOutsideAlerter(containerRef, () => {
    setShowPopover(false);
  });

  const renderSuggestions = () => (
    <>
      {suggestions.map((place, index) => (
        <span
          onClick={() => handleSelectLocation(place)}
          key={index}
          className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
        >
          <span className="block text-neutral-400">
            <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
          </span>
          <span className="block text-neutral-700 dark:text-neutral-200">
            {place}
          </span>
        </span>
      ))}
    </>
  );

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => {
          setShowPopover(true);
          if (onClick) onClick();
        }}
        className={`flex flex-1 relative z-10 [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
          showPopover ? "nc-hero-field-focused--2" : ""
        }`}
      >
        <div className="flex-1">
          <input
            className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
            placeholder={placeHolder || t("placeholder")}
            value={inputValue}
            autoFocus={showPopover}
            onChange={handleInputChange}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light">
            <span className="line-clamp-1">
              {!!inputValue ? t("placeholder") : t("description")}
            </span>
          </span>
          {inputValue && showPopover && (
            <ClearDataButton
              onClick={() => {
                setInputValue("");
                setLocation("");
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[400px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-5 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSuggestions()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
