import React, { FC, useEffect, useCallback, useState } from "react";
import LocationInputExperiences from "../LocationInputExperiences";
import GuestsInputExperiences from "../GuestsInputExperiences";
import ExperiencesDateInput from "./ExperiencesDateSingleInput";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext";

export interface ExperiencesSearchFormProps {
  defaultFieldFocus?: string;
  searchValue?: string;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckoutSelected: () => void;
  onValidate?: (errors: { locationError: boolean; dateError: boolean }) => void;
}

const ExperiencesSearchForm: FC<ExperiencesSearchFormProps> = ({
  defaultFieldFocus,
  searchValue,
  onSearchChange,
  onCheckoutSelected,
  onValidate,
}) => {
  const { from, to, setFrom, setTo } = useExperienceDatesContext();
  const { adults, children, infants, setAdults, setChildren, setInfants } = useExperienceGuestsContext();
  const { location, setLocation } = useExperienceLocationContext();
  const [activeInput, setActiveInput] = useState<string | null>(defaultFieldFocus || null);

  // Guardar el filtro de búsqueda en localStorage
  const saveSearchFilterToLocalStorage = () => {
    const searchFilter = {
      location,
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      adult: adults,
      children,
      infant: infants,
    };
    localStorage.setItem("searchFilterExperiences", JSON.stringify(searchFilter));
  };

  // Sincronizar con localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");
      setFrom(savedFilter.from ? new Date(savedFilter.from) : null);
      setTo(savedFilter.to ? new Date(savedFilter.to) : null);
      setLocation(savedFilter.location || "");
      setAdults(savedFilter.adult || 1);
      setChildren(savedFilter.children || 0);
      setInfants(savedFilter.infant || 0);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setFrom, setTo, setAdults, setChildren, setInfants, setLocation]);

  // Manejar selección de ubicación
  const handleLocationInputDone = useCallback(
    (value: string) => {
      setLocation(value);
      const locationError = !value;
      const dateError = !from || !to;

      if (onValidate) {
        onValidate({ locationError, dateError });
      }

      saveSearchFilterToLocalStorage();
      if (onSearchChange) {
        const simulatedEvent = {
          target: { value } as EventTarget & HTMLInputElement,
        };
        onSearchChange(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
      }
      setActiveInput(null);
    },
    [onSearchChange, setLocation, onValidate, from, to]
  );

  // Manejar cambios de fecha
  const handleDatesChange = (startDate: Date | null, endDate: Date | null) => {
    setFrom(startDate);
    setTo(endDate);
    const locationError = !location;
    const dateError = !startDate || !endDate;

    if (onValidate) {
      onValidate({ locationError, dateError });
    }

    saveSearchFilterToLocalStorage();
    if (startDate && endDate) setActiveInput(null);
  };

  // Validar campos obligatorios
  const validateFields = () => {
    const locationError = !location;
    const dateError = !from || !to;

    if (onValidate) {
      onValidate({ locationError, dateError });
    }

    return !locationError && !dateError;
  };

  // Manejar cierre del modal al presionar Escape o hacer clic fuera
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setActiveInput(null);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (
        activeInput &&
        !target.closest(".location-input-experiences") &&
        !target.closest(".experiences-date-input") &&
        !target.closest(".guests-input-experiences")
      ) {
        setActiveInput(null);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeInput]);

  return (
    <form className="relative flex rounded-full border border-neutral-300 dark:border-neutral-700 bg-white dark:bg-neutral-800 p-1">
      <LocationInputExperiences
        className="flex-[1.5] location-input-experiences"
        autoFocus={defaultFieldFocus === "location"}
        value={location}
        onInputDone={handleLocationInputDone}
        isOpen={activeInput === "location"}
        onClick={() => setActiveInput("location")}
      />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

      <ExperiencesDateInput
        className="flex-[1.1] experiences-date-input"
        isOpen={activeInput === "dates"}
        onDatesChange={handleDatesChange}
        onClick={() => setActiveInput("dates")}
      />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

      <GuestsInputExperiences
        className="flex-[1.5] guests-input-experiences"
        isOpen={activeInput === "guests"}
        onClick={() => setActiveInput("guests")}
        buttonSubmitHref="/listing-experiences"
        handleSearchClick={validateFields}
      />
    </form>
  );
};

export default ExperiencesSearchForm;
