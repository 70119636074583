import './i18n';
import React from "react";
import ReactDOM from "react-dom/client";
import { APIProvider } from "@vis.gl/react-google-maps";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./redux/store";
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import "rc-slider/assets/index.css";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContext";
import { LocationProvider } from "./contexts/LocationContext";
import { DatesProvider } from "./contexts/DatesContext"; // Importa el DatesProvider
import { GuestsProvider } from 'contexts/GuestsContext';
import { FiltersProvider } from 'contexts/FilterContext';
import { ExperiencesFilterProvider } from 'contexts/ExperiencesFilterContext';
import { ExperienceLocationProvider } from 'contexts/ExperienceLocationContext';
import { ExperienceDatesProvider } from 'contexts/ExperienceDatesContext';
import { ExperienceGuestsProvider } from 'contexts/ExperienceGuestsContext';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <Provider store={store}>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}>
            <LocationProvider>
              <DatesProvider>
                <GuestsProvider>
                  <FiltersProvider>
                    <ExperiencesFilterProvider>
                      <ExperienceLocationProvider>
                        <ExperienceDatesProvider>
                          <ExperienceGuestsProvider>
                            <App/>
                          </ExperienceGuestsProvider>
                        </ExperienceDatesProvider>
                      </ExperienceLocationProvider>
                    </ExperiencesFilterProvider>
                  </FiltersProvider>
                </GuestsProvider>
              </DatesProvider>
            </LocationProvider>
          </APIProvider>
        </Provider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
