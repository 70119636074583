import React, { FC, useState } from "react";
import StayCardH from "components/StayCardH/StayCardH";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters/TabFilters";
import Heading2 from "components/Heading/Heading2";
import MapContainer from "containers/MapContainer";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useFiltersContext } from "contexts/FilterContext";
import { useLocationContext } from "contexts/LocationContext"; // Importar el contexto de ubicación

export interface SectionGridHasMapProps {
  isMapVisible: boolean;
  onToggleMap: () => void;
}

const ITEMS_PER_PAGE = 4;

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  isMapVisible,
  onToggleMap,
}) => {
  const { t, i18n } = useTranslation("heading2");
  const { from, to } = useDatesContext();
  const { adults, children, infants } = useGuestsContext();
  const { location } = useLocationContext(); // Usar el contexto de ubicación
  const {
    priceRange,
    selectedTypes,
    guestCount,
    bedsCount,
    selectedAmenities,
    selectedFacilities,
  } = useFiltersContext();

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [currentPage, setCurrentPage] = useState(1);

  // Filtrado de los alojamientos
  const filteredStays = DEMO_STAY_LISTINGS.filter((stay) => {
    const price = parseFloat(stay.price.replace(/[$,]/g, ""));
    const matchesType = selectedTypes.length === 0 || selectedTypes.includes(stay.type);
    const matchesGuests = guestCount === 0 || stay.maxGuests >= guestCount;
    const matchesBeds = bedsCount === 0 || stay.beds >= bedsCount;
    const matchesAmenities =
      selectedAmenities.length === 0 || selectedAmenities.every((amenity) => stay.amenities.includes(amenity));
    const matchesFacilities =
      selectedFacilities.length === 0 || selectedFacilities.every((facility) => stay.facilities.includes(facility));

    return (
      price >= priceRange[0] &&
      price <= priceRange[1] &&
      matchesType &&
      matchesGuests &&
      matchesBeds &&
      matchesAmenities &&
      matchesFacilities
    );
  });

  // Obtener el total de páginas según los alojamientos filtrados
  const totalPages = Math.ceil(filteredStays.length / ITEMS_PER_PAGE);
  const paginatedStays = filteredStays.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Calcular el total de huéspedes
  const totalGuests = adults + children + infants;

  // Formato de fechas según el idioma actual
  const dateFormatOptions: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit" };
  const formattedFromDate = from ? from.toLocaleDateString(i18n.language, dateFormatOptions) : "";
  const formattedToDate = to ? to.toLocaleDateString(i18n.language, dateFormatOptions) : "";

  return (
    <div className="relative flex min-h-screen">
      <div className="w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 relative z-20">
        <Heading2
          onToggleMap={onToggleMap}
          isMapVisible={isMapVisible}
          heading={`${t("staysIn")} ${location || "Destino"}`} // Usar `location` del contexto o "Destino"
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {formattedFromDate} - {formattedToDate}
              <span className="mx-2">·</span>
              {t("numberOfGuests", { count: totalGuests })}
            </span>
          }
        />
        <div className="mb-8 lg:mb-11">
          <TabFilters />
        </div>
        <div className="grid grid-cols-1 gap-8">
          {paginatedStays.map((item) => (
            <div
              key={item.id}
              onMouseEnter={() => setCurrentHoverID(item.id)}
              onMouseLeave={() => setCurrentHoverID(-1)}
            >
              <StayCardH data={item} />
            </div>
          ))}
        </div>
        <div className="flex mt-16 justify-center items-center">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      {/* Contenedor del Mapa */}
      {isMapVisible && (
        <div className="flex-grow xl:ml-8 h-full xl:h-[calc(100vh-88px)] sticky top-0">
          <div className="w-full h-full rounded-md overflow-hidden">
            <MapContainer
              currentHoverID={currentHoverID}
              DEMO_DATA={filteredStays.length ? filteredStays : []}
              listingType="stay"
            />

            <ButtonClose
              onClick={onToggleMap}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridHasMap;
