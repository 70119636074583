import React, { FC, useState } from "react";
import { Popover } from "@headlessui/react";
import { useTranslation } from "react-i18next"; // Importar useTranslation
import ButtonPrimary from "shared/Button/ButtonPrimary"; // Importar ButtonPrimary

interface BedsAndBathroomsFiltersProps {
  setGuestCount: (count: number) => void;
  setBedsCount: (count: number) => void;
}

const BedsAndBathroomsFilters: FC<BedsAndBathroomsFiltersProps> = ({ setGuestCount, setBedsCount }) => {
  const { t } = useTranslation('bedsAndBathroomsFilters'); // Usar el namespace para traducción
  const [guests, setGuests] = useState(0);
  const [beds, setBeds] = useState(0);

  const handleIncrementGuests = () => {
    setGuests(guests + 1);
  };

  const handleDecrementGuests = () => {
    if (guests > 0) {
      setGuests(guests - 1);
    }
  };

  const handleIncrementBeds = () => {
    setBeds(beds + 1);
  };

  const handleDecrementBeds = () => {
    if (beds > 0) {
      setBeds(beds - 1);
    }
  };

  const handleApply = () => {
    setGuestCount(guests);
    setBedsCount(beds);
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none">
            <span>{t("bedsAndBathrooms")}</span> {/* Título del botón */}
          </Popover.Button>
          <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0">
            <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
              <div className="relative flex flex-col px-5 py-6 space-y-8">
                <div className="flex justify-between items-center">
                  <span>{t("beds")}</span>
                  <div className="flex items-center">
                    <button onClick={handleDecrementBeds} className="px-2 border">−</button>
                    <span className="mx-2">{beds}</span>
                    <button onClick={handleIncrementBeds} className="px-2 border">+</button>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>{t("bathrooms")}</span>
                  <div className="flex items-center">
                    <button onClick={handleDecrementGuests} className="px-2 border">−</button>
                    <span className="mx-2">{guests}</span>
                    <button onClick={handleIncrementGuests} className="px-2 border">+</button>
                  </div>
                </div>
              </div>
              <div className="p-5 bg-neutral-50 flex items-center justify-between">
                <button 
                  onClick={() => { 
                    setGuests(0); 
                    setBeds(0); 
                  }} 
                  className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full text-sm sm:text-base font-medium px-4 py-2 border border-neutral-200 text-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">
                  {t("clear")}
                </button>
                <ButtonPrimary 
                  onClick={handleApply} 
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  {t("apply")}
                </ButtonPrimary>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default BedsAndBathroomsFilters;
