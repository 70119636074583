import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import convertNumbThousand from "utils/convertNumbThousand";
import BedsAndBathroomsFilters from "./BedsAndBathroomsFilters";
import MoreFilters from "./MoreFilters";
import { useTranslation } from "react-i18next";
import { useFiltersContext } from "contexts/FilterContext";

const typeOfPlaces = [
  { name: "entirePlace", descriptionKey: "entirePlaceDescription" },
  { name: "privateRoom", descriptionKey: "privateRoomDescription" },
  { name: "hotelRoom", descriptionKey: "hotelRoomDescription" },
  { name: "sharedRoom", descriptionKey: "sharedRoomDescription" },
];

const TabFilters: React.FC = () => {
  const { t } = useTranslation("tabFilters");

  // Utiliza el contexto de filtros
  const {
    priceRange,
    setPriceRange,
    selectedTypes,
    setSelectedTypes,
    guestCount,
    setGuestCount,
    bedsCount,
    setBedsCount,
    selectedAmenities,
    setSelectedAmenities,
    selectedFacilities,
    setSelectedFacilities,
  } = useFiltersContext();

  // Estados temporales para los tipos de lugar y rango de precio
  const [tempSelectedPlaceTypes, setTempSelectedPlaceTypes] = useState<string[]>(selectedTypes);
  const [tempPriceRange, setTempPriceRange] = useState(priceRange);
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);

  useEffect(() => {
    setTempSelectedPlaceTypes(selectedTypes);
  }, [selectedTypes]);

  // Maneja el cambio de tipos de lugar
  const handleTypeChange = (type: string) => {
    setTempSelectedPlaceTypes((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((t) => t !== type)
        : [...prevTypes, type]
    );
  };

  // Aplica los tipos de lugar seleccionados temporalmente
  const handleApply = () => {
    setSelectedTypes(tempSelectedPlaceTypes);
  };

  const renderTabsTypeOfPlace = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
          >
            <span>{t("typeOfPlace")}</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {typeOfPlaces.map((item) => (
                    <div key={item.name}>
                      <Checkbox
                        name={item.name}
                        label={t(item.name)}
                        subLabel={t(item.descriptionKey)}
                        checked={tempSelectedPlaceTypes.includes(item.name)}
                        onChange={() => handleTypeChange(item.name)}
                      />
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setTempSelectedPlaceTypes([])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      handleApply();
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  const renderTabsPriceRange = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-400 bg-primary-50 text-primary-400 focus:outline-none">
            <span>{`$${convertNumbThousand(tempPriceRange[0])} - $${convertNumbThousand(tempPriceRange[1])}`}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-8">
                  <span className="font-medium">{t("pricePerDay")}</span>
                  <Slider
                    range
                    min={0}
                    max={2000}
                    value={tempPriceRange}
                    allowCross={false}
                    onChange={(value) => setTempPriceRange(value as number[])}
                  />
                  <div className="flex justify-between space-x-5">
                    <div className="w-1/2">
                      <label htmlFor="minPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("minPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="minPrice"
                          id="minPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={tempPriceRange[0]}
                          min={0}
                          max={tempPriceRange[1]}
                          onChange={(e) => setTempPriceRange([Number(e.target.value), tempPriceRange[1]])}
                        />
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="maxPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("maxPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="maxPrice"
                          id="maxPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={tempPriceRange[1]}
                          min={tempPriceRange[0]}
                          max={2000}
                          onChange={(e) => setTempPriceRange([tempPriceRange[0], Number(e.target.value)])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setTempPriceRange([0, 2000])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      setPriceRange(tempPriceRange);
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  // Funciones para manejar la apertura y cierre del modal More Filters
  const openMoreFilters = () => setIsMoreFiltersOpen(true);
  const closeMoreFilters = () => setIsMoreFiltersOpen(false);

  // Función para aplicar los filtros adicionales (amenidades y facilidades)
  const handleApplyMoreFilters = (amenities: string[], facilities: string[]) => {
    setSelectedAmenities(amenities);
    setSelectedFacilities(facilities);
    closeMoreFilters();
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfPlace()}
        {renderTabsPriceRange()}
        <BedsAndBathroomsFilters setGuestCount={setGuestCount} setBedsCount={setBedsCount} />
        <MoreFilters
          isOpen={isMoreFiltersOpen}
          openModal={openMoreFilters}
          closeModal={closeMoreFilters}
          selectedAmenities={selectedAmenities}
          selectedFacilities={selectedFacilities}
          onApply={handleApplyMoreFilters}
          onClear={() => {
            setSelectedAmenities([]);
            setSelectedFacilities([]);
          }}
        />
      </div>
    </div>
  );
};

export default TabFilters;
