import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { FC, Fragment, useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const LangDropdown: FC<{ panelClassName?: string }> = ({
  panelClassName = "z-10 w-screen max-w-[280px] px-4 mt-4 right-0 sm:px-0",
}) => {
  const { t } = useTranslation("languageAndCurrency");
  const [languages, setLanguages] = useState([
    {
      id: "en",
      name: "English",
      description: "United States",
      active: i18n.language === "en",
    },
    {
      id: "es",
      name: "Español",
      description: "España",
      active: i18n.language === "es",
    },
  ]);

  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  // Cargar el idioma desde localStorage al iniciar
  useEffect(() => {
    const storedLang = localStorage.getItem("langIsoCode");
    if (storedLang && storedLang !== i18n.language) {
      i18n.changeLanguage(storedLang);
    }
  }, []);

  // Actualizar el idioma activo y guardar en localStorage
  useEffect(() => {
    const updatedLanguages = languages.map((lang) => ({
      ...lang,
      active: lang.id === i18n.language,
    }));
    setLanguages(updatedLanguages);

    // Actualizar el idioma seleccionado y guardar en localStorage
    const currentLanguage = updatedLanguages.find(
      (lang) => lang.id === i18n.language
    );
    setSelectedLanguage(currentLanguage || updatedLanguages[0]);
    localStorage.setItem("langIsoCode", i18n.language);
  }, [i18n.language]);

  return (
    <div className="LangDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`${
                open ? "" : "text-opacity-80"
              } group px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <GlobeAltIcon className="w-[18px] h-[18px] opacity-80" />
              <span className="ml-2 select-none">{selectedLanguage.name}</span>
              <ChevronDownIcon
                className={`${
                  open ? "-rotate-180" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute ${panelClassName}`}>
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-1 bg-white dark:bg-neutral-800 p-4">
                    {languages.map((item) => (
                      <button
                        key={item.id}
                        onClick={() => {
                          i18n.changeLanguage(item.id);
                          setSelectedLanguage(item);
                          localStorage.setItem("langIsoCode", item.id);
                          close();
                        }}
                        className={`flex flex-col items-start p-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                          item.active
                            ? "bg-gray-100 dark:bg-neutral-700"
                            : "opacity-80"
                        }`}
                      >
                        <p className="text-sm font-medium">{item.name}</p>
                        <p className="text-xs text-gray-500 dark:text-neutral-400">
                          {item.description}
                        </p>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default LangDropdown;
